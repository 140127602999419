.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 24px;
}

.paginationContainer-p {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571429em;
  letter-spacing: -0.04em;
  color: #424242;
}

.paginationContainer-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.disabled-arrow {
  opacity: 0.6;
  cursor: not-allowed !important;
  // svg {
  //   path {
  //     opacity: 0.8;
  //   }
  // }
}
