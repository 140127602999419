.dropDownButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px 2px 8px;
  gap: 4px;
  background: #f5f5f5;
  mix-blend-mode: multiply;
  border-radius: 4px;
  justify-content: flex-start;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: -0.04em;
  color: #424242;
  width: fit-content;
}
.cursorPointer {
  cursor: pointer;
}
.dropDownButton svg path {
  stroke: #737373;
}

ul,
li {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.listwrapper {
  display: flex;
  max-height: 268px;
  background-color: white;
  border: 1px solid #e5e5e5;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  z-index: 5;
  overflow-y: auto;
}
.listcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 6px;
  flex-grow: 1;
}

.listitem {
  display: flex;
  padding: 10px 8px;
  align-self: stretch;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #101828;
  justify-content: flex-start;
}

.listitem:hover {
  border-radius: 6px;
  background-color: #f9fafb;
}

.listitem-full {
  display: flex;
  padding: 10px 8px;
  align-self: stretch;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #101828;
  justify-content: flex-start;
}

.listitem-full:hover {
  border-radius: 6px;
  background-color: #f9fafb;
}

.customInput {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.removeCategoryColor {
  color: #b42318;
}

.dropDownSpan {
  display: flex;
  text-align: left;
  word-wrap: break-word;
}
