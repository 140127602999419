@import "./../../../variables.scss";

.mainPart {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
  @media screen and (min-width: $breakpoint-xs) {
    width: 1216px;
    align-self: center;
    margin: 40px auto 92px;
    max-width: 88vw;
  }
  &.larger {
    padding-right: 0;
    gap: 0;
    .megaWrapper {
      height: inherit;
      gap: 0;
    }
  }
  &.noMarginBottom {
    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: 8px;
    }
  }
}

label {
  font-weight: 500;
  color: #737373;
}

.buttonHolder {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
  position: absolute;
  width: 100%;
  padding: 16px 24px;
  background: white;
  bottom: 0;
  &.withProgressBar {
    bottom: 7px;
  }
  @media screen and (min-width: $breakpoint-xs) {
    position: relative;
    align-self: baseline;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 12px 32px;
    &.withProgressBar {
      padding: 0 12px 56px;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    padding: 0 0 32px;
    &.withProgressBar {
      padding: 0 0 56px;
    }
    width: 100%;
    max-width: 1216px;
    margin: auto;
  }
  .backButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    padding-left: 0px;
    gap: 8px;
    flex: none;
    flex-grow: 1;
    cursor: pointer;
    button {
      svg {
        @media screen and (min-width: $breakpoint-xs) {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .nextButtonWrapper {
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
    display: flex;
    .noChevron {
      padding: 8px 6px 8px 14px;
    }
    .nextButton {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 6px 8px 14px;
      @media screen and (min-width: $breakpoint-xs) {
        padding: 16px 28px;
        gap: 12px;
      }
      gap: 8px;
      background: #ffffff;
      border: 1px solid #d6d6d6;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 32px;
      flex: none;
      cursor: pointer;
      &.primary {
        padding: 8px 6px 8px 14px;
        background-color: unset;
        @media screen and (min-width: $breakpoint-xs) {
          padding: 16px 28px;
          background-color: $brand-purple;
        }
        span {
          color: #ffffff;
          @media screen and (min-width: $breakpoint-xs) {
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
          }
        }
        svg {
          stroke: #ffffff;
        }
      }
      &.noAccent {
        padding: 8px 6px 8px 14px;
        background-color: #ffffff;
        @media screen and (min-width: $breakpoint-xs) {
          padding: 16px 28px;
        }
        span {
          color: #424242;
        }
        svg {
          stroke: #ffffff;
        }
      }
      &.invalid {
        svg {
          stroke: #d6d6d6;
        }
        span {
          font-family: "Indivisible";
          font-weight: 600;
          font-style: normal;
        }
      }
      &.noChevron {
        padding: 8px 14px;
        @media screen and (min-width: $breakpoint-xs) {
          padding: 16px 28px;
        }
      }
      svg {
        stroke: #424242;
        width: 20px;
        height: 20px;
        @media screen and (min-width: $breakpoint-xs) {
          width: 24px;
          height: 24px;
        }
      }
      span {
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #424242;
        @media screen and (min-width: $breakpoint-xs) {
          font-size: 18px;
          line-height: 28px;
          word-wrap: break-word;
        }
      }
    }
  }

  button {
    border: none;
    background: none;
  }
}

.createAccountWrap {
  width: 100%;
  .buttonText {
    font-size: 18px;
    line-height: 28px;
    @media screen and (min-width: $breakpoint-xs) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media screen and (min-width: $breakpoint-xs) {
    display: flex;
    gap: 128px;
    align-items: center;
  }
}
.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex: none;
  width: 100%;
  @media screen and (min-width: $breakpoint-xs) {
    gap: 16px;
    max-width: 848px;
    flex: 1;
  }
  .smallInfo {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    width: 225px;
    letter-spacing: -0.04em;
    color: #a3a3a3;
    word-wrap: break-word;
    @media screen and (min-width: $breakpoint-xs) {
      font-size: 24px;
      line-height: 32px;
      white-space: nowrap;
    }
  }
}

.separator {
  height: 20px;
}

.inputDropDown {
  width: 100%;
  @media screen and (min-width: $breakpoint-xs) {
    width: 100%;
    max-width: 908px;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    svg {
      margin: 10px 10px 10px 8px;
    }
    &:hover {
      background-color: #fafafa;
      cursor: pointer;
      border-radius: 6px;
    }
    .addressItem {
      padding: 4px;
      gap: 0px;
      align-self: stretch;
      flex-grow: 0;
      width: fit-content;
      @media screen and (min-width: $breakpoint-xs) {
        flex: 1;
        width: 100%;
        padding: 10px 8px;
      }
      .mainText {
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #000000;
        padding-right: 8px;

        @media screen and (min-width: $breakpoint-xs) {
          font-size: 20px;
          font-family: Indivisible;
          font-weight: 500;
          line-height: 30px;
          word-wrap: break-word;
        }
      }
      .secondaryText {
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #475467;
        flex-grow: 1;
        @media screen and (min-width: $breakpoint-xs) {
          color: #525252;
          font-size: 20px;
          font-family: Indivisible;
          font-weight: 400;
          line-height: 30px;
          word-wrap: break-word;
        }
      }
    }
  }
}

.step14,
.createAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  max-width: 908px;
  .row {
    flex-wrap: wrap;
    .megaWrapper {
      max-width: calc(876px / 2);
      @media screen and (min-width: $breakpoint-xs) {
        width: calc(876px / 2);
      }
    }
  }
  .megaWrapper {
    height: 88px;
    @media screen and (min-width: $breakpoint-xs) {
      width: 100%;
    }
    .inputLabel {
      font-size: 12px;
    }
    .textInputWrapper {
      input {
        font-size: 30px;
        line-height: 38px;
      }
    }
    .inputSpan {
      &.large {
        @media screen and (min-width: $breakpoint-xs) {
          font-size: 72px;
        }
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (min-width: $breakpoint-xs) {
    flex-direction: row;
    gap: 32px;
  }
}

.createAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
  .crossAuthLinkerWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    justify-content: space-between;
    @media screen and (min-width: $breakpoint-xs) {
      padding-top: 16px;
      padding-left: 32px;
      padding-right: 32px;
      width: unset;
    }
    &.noPadding {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
    p {
      color: #737373;
      font-size: 18px;
      font-family: Indivisible;
      font-weight: 600;
      line-height: 28px;
      word-wrap: break-word;
    }
    a {
      color: #a459ff;
      font-size: 18px;
      font-family: "Indivisible";
      font-weight: 600;
      line-height: 28px;
      word-wrap: break-word;
    }
  }
  &.loginContainer {
    gap: 16px;
    .formWrapper {
      gap: 24px;
    }
  }
  &.verifyEmail {
    @media screen and (min-width: $breakpoint-xs) {
      gap: 24px;
    }
    .inputLabel {
      line-height: 18px;
      color: #a3a3a3;
    }
  }
  .addStroke {
    svg {
      stroke: #141414;
      width: 40px;
      height: 40px;
      @media screen and (min-width: $breakpoint-xs) {
        width: 48px;
        height: 48px;
      }
    }
  }
  @media screen and (min-width: $breakpoint-xs) {
    gap: 40px;
    max-width: 920px;
  }
  span {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #a3a3a3;
    word-wrap: break-word;
    @media screen and (min-width: $breakpoint-xs) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &.forgotPasswordContainer {
    gap: 16px;
    span {
      font-size: 12px;
      line-height: 18px;
    }
    .disclaimer {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }
  .buttonWrap {
    gap: 16px;
  }
  &.step21 {
    .formWrapper {
      .tooltip {
        margin: -12px 0;
      }
      .row {
        gap: 16px;
        @media screen and (min-width: $breakpoint-xs) {
          gap: 32px;
          width: 100%;
        }
      }
      @media screen and (min-width: $breakpoint-xs) {
        gap: 24px;
      }
    }
  }
}

.boxesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  align-self: stretch;
  .boxLabel {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #424242;
  }
  .boxes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
    gap: 4px;
    @media screen and (min-width: $breakpoint-xs) {
      gap: 12px;
    }

    span {
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 72px;
      /* or 120% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.04em;

      /* Gray/300 */

      color: #d6d6d6;
    }
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2px;
      gap: 8px;
      width: 48px;
      height: 48px;
      max-width: 60px;
      background: #ffffff;
      border: 1px solid #d6d6d6;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      justify-content: center;
      @media screen and (min-width: $breakpoint-xs) {
        width: 64px;
        height: 64px;
        font-size: 30px;
      }

      &:focus {
        border: 2px solid $brand-purple;
        box-shadow:
          0px 0px 0px 4px #f4ebff,
          0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
      &:hover {
        background-color: transparent;
      }

      &.active {
        border: 2px solid $brand-purple;
      }

      &.current {
        box-shadow:
          0px 0px 0px 4px #f4ebff,
          0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      &.invalid {
        border: 2px solid #d92d20;
      }

      input {
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.04em;
        color: #d6d6d6;
        border: none;
        background-color: transparent;
        width: 100%;
        caret-color: transparent;
        padding: 0px;
        &.active {
          color: #7f56d9;
        }
        &.invalid {
          color: #d92d20;
        }
      }
      span {
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        letter-spacing: -0.04em;
        color: #d6d6d6;
      }
    }
  }
  .errorMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 10px 2px 8px;
    gap: 4px;

    background: #fef3f2;
    mix-blend-mode: multiply;
    border-radius: 4px;
    span {
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.04em;
      color: #b42318;
    }
  }
}

.forgotPassword {
  color: #a459ff;
  font-family: Indivisible;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.termsAndConditions {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  a {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a459ff;
    text-decoration: none;
  }
}
