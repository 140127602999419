.vh-70 {
  height: 70vh;
}

.flex-space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.pagination-text-in-middle {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 22px;
}
.drop-down-reject-reason {
  display: flex;
  align-items: center;
  gap: 22px;
  padding-top: 12px;
}

.sub-header-wrap {
  padding: 0 24px 16px 24px;
  margin: 0;
}

.icon-status {
  display: inline-flex;
  align-items: center;
  gap: 22px;
}

.rejection-reason {
  margin-top: 4px;
  font-size: 14px;
}

.viewer {
  padding-bottom: 0;
  border-radius: 4px;
  height: 83vh;
}

.subDocument-text {
  color: var(--gray-600, #525252);

  /* Text sm/Regular */
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
}
