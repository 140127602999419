.user-email {
  font-family: "Indivisible", "Arial";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
}

.badge-text {
  font-family: "Indivisible", "Arial" !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424242;
}

.edit-icon {
  color: #737373;
}

.pending-badge {
  color: var(--warning-700, #b54708);
  text-align: center;

  font-family: "Indivisible";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.48px;

  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 4px;
  background: var(--warning-50, #fffaeb);
}
