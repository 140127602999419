$colors: (
  red: #f04438,
  green: #62d1a4,
  orange: #f79009,
  gray: #737373,
  blue: #2e90fa,
);

@each $color-name, $color-code in $colors {
  .bg-#{$color-name} {
    background-color: $color-code;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}
