.documents-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.96px;
  color: #141414;
}

.MuiTab-root {
  padding-bottom: 0px !important;
}
