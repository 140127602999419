.updatedDate {
  color: #737373;
  /* Text sm/Medium */
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
  white-space: nowrap;
}

.flex-basis-2 {
  flex-basis: 20%;
}

.grid-cols-4 {
  display: grid;
  gap: 12px;
  grid-template-columns: 7fr 2fr 3fr 2fr;
}

@media screen and (min-width: 1600px) {
  .grid-cols-4 {
    grid-template-columns: 9fr 2fr 3fr 2fr;
  }
}

.chevron_disabled {
  color: #a3a3a3;
  font-family: "Indivisible", "sans-serif";
  font-size: 14px;
}

.chevron__rotate {
  transition: transform 0.3s; /* Add a smooth transition effect */

  &.open {
    transform: rotate(90deg); /* Rotate 90 degrees clockwise when open */
  }
}

.doublechevron_rotate {
  transition: transform 0.3s; /* Add a smooth transition effect */

  &.open {
    transform: rotate(90deg); /* Rotate 90 degrees clockwise when open */
  }
}
