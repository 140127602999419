@import "./../../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");

*:focus {
  outline: none;
}
.onBoardingHeader {
  width: 100%;
  background-color: $brand-purple;
  padding: 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 32px;
  height: 298px;
  display: flex;
  flex-direction: column;
  svg {
    cursor: pointer;
  }
  @media screen and (min-width: $breakpoint-xs) {
    height: 312px;
    margin-bottom: 52px;
  }
  .logBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px;
    gap: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 100%;
    @media screen and (min-width: $breakpoint-xs) {
      align-self: baseline;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      width: 1216px;
      margin: auto;
      height: 96px;
      padding: 8px 0;
      max-width: 88vw;
    }
    @media screen and (min-width: $breakpoint-md) {
    }
    .contactHelp {
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0px;
      gap: 2px;
      flex: none;
      order: 1;
      flex-grow: 1;
      .buttonWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        @media screen and (min-width: $breakpoint-xs) {
          padding: 8px 14px;
        }
        &.helpCircle {
          box-sizing: border-box;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 32px;
          @media screen and (min-width: $breakpoint-xs) {
            padding: 8px 14px;
            cursor: pointer;
          }
          span {
            color: #424242;
            font-size: 14px;
            font-family: Indivisible;
            font-weight: 600;
            line-height: 20px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .textSupport {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 24px 24px;
    gap: 12px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 1;
    white-space: inherit;
    @media screen and (min-width: $breakpoint-xs) {
      padding: 0;
      align-self: center;
      min-height: 156px;
      width: 1216px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 32px;
      max-width: 88vw;
    }
    .firstRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      gap: 4px;
      width: max-content;
      @media screen and (min-width: $breakpoint-xs) {
        width: initial;
        gap: 6px;
        place-items: flex-start;
        svg {
          margin-top: 4px;
          width: 20px;
          height: 20px;
        }
      }
    }
    p {
      font-family: "Indivisible";
      font-style: normal;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
      word-wrap: break-word;
      display: flex;
      flex: 2;
      color: #ffffff;
      order: 0;
      letter-spacing: -0.04em;
      align-self: stretch;
      flex-grow: 0;
      align-items: center;
      @media screen and (min-width: $breakpoint-xs) {
        width: 908px;
        // padding: 48px 0;
        // place-items: end;
        &.secondaryAddress {
          width: 100%;
        }
        flex: 2;
        font-size: 48px;
        line-height: 60px;
      }
    }
    .subTextSupport {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px;
      gap: 2px;
      flex: none;
      width: 26%;
      @media screen and (min-width: $breakpoint-xs) {
        align-items: flex-end;
        margin-left: 16px;
        max-width: 250px;
      }
      .primaryAddress {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        width: inherit;
        white-space: nowrap;
        @media screen and (min-width: $breakpoint-xs) {
          font-size: 18px;
          line-height: 28px;
          word-wrap: break-word;
          text-align: right;
          text-wrap: initial;
        }
      }
      .secondaryAddress {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        @media screen and (min-width: $breakpoint-xs) {
          align-self: self-end;
          justify-content: end;
          text-wrap: wrap;
          text-align: end;
          font-size: 18px;
          line-height: 28px;
          word-wrap: break-word;
          text-wrap: initial;
        }
      }
    }
  }
}
.modalContainerWrapper {
  width: 100%;
  @media screen and (min-width: $breakpoint-xs) {
    margin: 20px 20px 0 auto;
    width: initial;
  }
  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    padding: 0px;
    position: relative;
    width: 100%;
    justify-content: flex-start;
    background: #ffffff;
    box-shadow:
      0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    @media screen and (min-width: $breakpoint-xs) {
      width: 400px;
      border-radius: 12px;
    }
    .customMargin {
      margin-bottom: 32px;
      color: #a3a3a3;
      font-size: 36px;
      font-weight: 500;
      line-height: 44px;
    }
    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 24px 24px;
      gap: 8px;
      flex: none;
      align-self: stretch;
      .bigButton {
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align-last: start;
        padding: 24px;
        gap: 8px;
        border: 1px solid #e5e5e5;
        border-radius: 12px;
        flex: none;
        align-self: stretch;
        p {
          align-self: center;
          font-family: "Indivisible";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.04em;
          color: #141414;
          flex: none;
          flex-grow: 1;
          word-wrap: break-word;
        }
      }
    }
    .modalContainerHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 20px;
      gap: 16px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      position: relative;

      span {
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.04em;
        color: #525252;
      }

      p {
        font-family: "Indivisible";
        font-style: normal;
        text-align: center;
        letter-spacing: -0.04em;
        color: #141414;
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
        word-wrap: break-word;
      }
      .closeButton {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: absolute;
        right: 16px;
        top: 16px;
      }
    }
    .modalContainerContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 24px;
      gap: 20px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      .inputField {
        label {
          color: #424242;
          font-weight: 600;
        }
        input {
          color: #737373;
          font-size: 16px;
          line-height: 24px;
          word-wrap: break-word;
          &:focus {
            border: 1px solid #d6bbfb !important;
            box-shadow:
              0px 0px 0px 4px #f4ebff,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
          &:hover {
            background-color: transparent !important;
          }
        }
        textarea {
          padding: 12px;
          height: 126px;
          &:focus {
            border: 1px solid #d6bbfb !important;
            box-shadow:
              0px 0px 0px 4px #f4ebff,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
          &:hover {
            background-color: transparent !important;
          }
        }
        .inputError {
          color: #d92d20;
          font-size: 12px;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        flex: none;
        align-self: stretch;
        flex-grow: 0;

        .inputField {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 6px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
        }
      }
      .submitArea {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0px 24px;
        align-self: stretch;

        button[type="submit"] {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 18px;
          gap: 8px;
          background: #a459ff;
          border: 1px solid #a459ff;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 32px;
          align-self: stretch;
          span {
            font-family: "Indivisible";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            letter-spacing: -0.04em;

            /* Base/White */

            color: #ffffff;
          }
        }
      }
    }
    p {
      text-decoration: none;
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.04em;
      color: #000000;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }
    .modalContainerAction {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 24px 24px;
      gap: 12px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      a {
        text-decoration: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 18px;
        gap: 8px;
        background: #a459ff;
        border: 1px solid #a459ff;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 32px;
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #ffffff;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }
    }
  }
}

.callLinkContainer {
  a {
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 4px;
    gap: 8px;
    background: #a459ff;
    border: 1px solid #a459ff;
    border-radius: 32px;
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
}

label {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #424242;
  flex: none;
}

input[type="email"] {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  transition: all 0.3s ease-in-out;

  &:focus {
    border-color: $brand-purple;
  }
}

textarea {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 14px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
  resize: none;
  transition: all 0.3s ease-in-out;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #141414;
  &:focus {
    border-color: $brand-purple;
  }
}
