.iconStatus {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
}

.fileBackground {
  background: var(--interaction-surface-grey, #f5f5f5);
}

.containerDocOrFileBadge {
  display: flex;
  padding: 4px 14px 4px 6px;
  align-items: center;
  gap: 6px;
  &__border {
    border-radius: 20px;
    border: 1px solid var(--stroke-subtle-border, #e5e5e5);
  }
}

.documentTextBox {
  overflow: hidden;
  color: var(--text-or-icon-primary, #141414);
  text-overflow: ellipsis;
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.48px;
}
.fileTextBox {
  overflow: hidden;
  color: var(--text-or-icon-primary, #141414);
  text-overflow: ellipsis;
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.48px;
}

.rejectionReasonText {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--text-or-icon-destructive, #b42318);
  text-overflow: ellipsis;
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.48px;
}
