@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");

*:focus {
  outline: none;
}

* {
  margin: 0px;
  padding: 0px;
}

.onboardingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: relative;
  background: #ffffff;
  height: 100%;
  width: 100%;
  .onboardingMainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px 24px;
    gap: 8px;
    flex: none;
    order: 1;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.onboardingHeader {
  height: 348px;
  width: 100%;
  background-color: $brand-purple;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-top: 32px;
  gap: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 32px;
  .logBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px;
    gap: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 100%;
    .contactHelp {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0px;
      gap: 2px;
      flex: none;
      order: 1;
      flex-grow: 1;
      .buttonWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        &.helpCircle {
          box-sizing: border-box;
          background: #ffffff;
          border: 1px solid #d6d6d6;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 32px;
        }
      }
    }
  }
  .textSupport {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px;
    gap: 12px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 1;
    .firstRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;
    }
    p {
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      display: flex;
      align-items: flex-end;
      color: #ffffff;
      flex: none;
      order: 0;
      letter-spacing: -0.04em;
      align-self: stretch;
      flex-grow: 0;
    }
    .subTextSupport {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 2px;
      flex: none;
      .primaryAddress {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .secondaryAddress {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.inputSpan {
  margin: 0px;
  padding: 0px;
  // display: flex;
  min-width: 1px;
  // caret-color: transparent;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  align-items: center;
  letter-spacing: -0.04em;
  color: #141414;
  border: none;
  white-space: nowrap;
  // max-width: 88vw;
  width: 88vw;
  &.large {
    font-size: 60px;
    line-height: 72px;
  }
  &.notValid {
    color: #b42318;
  }
  &.disabled {
    color: #a3a3a3;
  }

  &::placeholder {
    color: #d6d6d6;
  }
  &:focus::placeholder {
    color: #f4ebff;
  }
}

.validationError {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px 2px 6px;
  gap: 4px;
  background: #fef3f2;
  mix-blend-mode: multiply;
  border-radius: 4px;
  span {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.04em;
    white-space: nowrap;
    /* Error/700 */

    color: #b42318;
  }
}
