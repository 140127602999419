.chevron__to__right {
  transform: rotate(180deg);
}

.chevron__to__bottom {
  transform: rotate(270deg);
}

.chevron {
  width: 32px;
  height: 32px;
}

.icon-dashed {
  border: 1px dashed #d6d6d6 !important;
}

.avatar-hover:hover {
  background-color: #eaecf0 !important;
  transition: 0.2s all;
}

.avatar-tooltip {
  font-family: Indivisible;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.04em;
  text-align: left;
}

.avatar-tooltip-text {
  @extend .avatar-tooltip;
  font-weight: 500;
}

.regular-tooltip {
  @extend .avatar-tooltip;
  line-height: 12px;
}
