.tabsContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
  gap: 1.5em;
}

.tabsContainer-utilities {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
  flex: none;
}
.tabsContainer-utilities__left {
  display: flex;
  direction: row;
  gap: 16px;
  align-items: center;
}

.MuiTableContainer-root {
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

.MuiTable-root {
  border-collapse: separate !important;
}

.MuiTableCell-root {
  padding: 0px !important;
}

.radioLabel {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.04em;

  /* Gray/700 */

  color: #424242;
}
/* Table Header text*/

.headerCell-text {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #525252;
  text-wrap: nowrap;
}
.templateSecondary-title--p,
.templatesTitle-secondary--p,
.templatesTitle-main--p,
.categoryContainer-text,
.documents--documentTitle {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4286em;
  letter-spacing: -0.04em;
  color: #141414;
  text-wrap: nowrap;
}

.templatesTitle-secondary--p {
  color: #525252;
  font-weight: 400;
}

.categoryContainer-input {
  border: 0px solid;
  padding: 0px;
  width: 100%;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4286em;
  letter-spacing: -0.04em;
  color: #737373;
  background-color: transparent;
}

.subrow-documents {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4286em;
  letter-spacing: -0.04em;
  color: #141414;
  text-wrap: nowrap;
}

.deleteModal-h2 {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 1.266667em;
  text-align: center;
  letter-spacing: -0.04em;
  color: #101828;
}

.deleteModal-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.42856em;
  text-align: center;
  letter-spacing: -0.04em;
  color: #475467;
}

.redRowLeft {
  border: 2px solid #d92d20 !important;
  border-right: 0px solid !important;
}
.redRowRight {
  border: 2px solid #d92d20 !important;
  border-left: 0px solid !important;
}
.redRowMiddle {
  border: 2px solid #d92d20 !important;
  border-left: 0px solid !important;
  border-right: 0px solid !important;
}
.roundBorderLeft {
  border: 2px solid #d92d20 !important;
  border-right: 0px solid !important;
  border-bottom-left-radius: 12px !important;
}
.roundBorderRight {
  border: 2px solid #d92d20 !important;
  border-bottom-right-radius: 12px !important;
  border-left: 0px solid !important;
}
.roundBorderMiddle {
  border: 2px solid #d92d20 !important;
  border-bottom-right-radius: 12px !important;
  border-left: 0px solid !important;
  border-right: 0px solid !important;
}

.toastMessageContainer {
  display: flex;
  align-items: center;
}
.succesIconBackground1 {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  background-color: #d1fadf;
  border: 6px solid #ecfdf3;
  border-radius: 28px;
}
.toastMessage-text {
  margin-left: 20px;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.42856em;
  letter-spacing: -0.04em;
  color: #141414;
}
