.bodyPasswordModal {
  color: var(--gray-500, var(--gray-500, #737373));
  text-align: center;
  font-family: Indivisible;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.64px;
}
