// variables
$breakpoint-xxxs: 280px;
$breakpoint-xxs: 380px;
$breakpoint-xs: 767px;
$breakpoint-sm: 992px;
$breakpoint-smmd: 1023px;
$breakpoint-md: 1279px;
$breakpoint-lg: 1400px;

$mainnav-height: 130px;
$mainnav-mob-height: 64px;
$min-height: min(100vh, 1000px);
$breakpoint-height: 1200px;

//colors
$red: #f51818;
$purple: #6941c6;
$focus-purple: #d6bbfb;
$brand-purple: #a459ff;

// ------------Mixins Definitions------------------

@mixin flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column() {
  @include flex();
  flex-direction: column;
}

@mixin flex-row() {
  @include flex();
  flex-direction: row;
}

@mixin overflow-fix() {
  overflow-y: hidden !important;
  overflow-x: hidden;
}

@mixin dotted-hori() {
  background-image: linear-gradient(to right, #979797 53%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 18px 1px;
  background-repeat: repeat-x;
}

@mixin dotted-verti() {
  background-image: linear-gradient(#979797 53%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 18px;
  background-repeat: repeat-y;
}

@mixin pseudo_abs() {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
}

@mixin plain_box() {
  float: left;
  width: 100%;
  background-color: var(--base-white);
  border-radius: 8px;
  margin: 15px 0;
  padding: 15px;
  border: 1px solid var(--border-color-dark-only);
  transition:
    background 0.4s,
    color 0.5s;
}

// -----------end of Mixins-------------
