@import './../../variables.scss';

.footer_section {
  z-index: 2;
  position: relative;
  background-color: #ffffff;
  max-width: 1280px;
  margin: auto;
  .content_wrap {
    .footer_wrap {
      padding-top: 90px;
      padding-bottom: 32px;
      border-bottom: 1px solid #eaecf0;
      flex-wrap: wrap;

      .left_content {
        margin-bottom: 24px;

        .we_are {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
          color: #475467;
          margin-top: 32px;
        }
      }

      .footer_items {
        .item {
          .title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-transform: capitalize;
            color: #98a2b3;
            margin-bottom: 16px;
          }

          .links {
            @include flex-column();
            align-items: flex-start;

            .footer_link {
              text-decoration: none !important;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #475467;
              margin-bottom: 12px;
              transition: opacity 0.3s;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          &.item:not(:last-child) {
            margin-right: 88px;
          }

          &:hover {
            opacity: 1;
          }

          .social_media_icons {
            min-width: 168px;

            a {
              &:not(:last-child) {
                margin-right: 24px;
              }

              svg {
                width: 24px;
                height: 24px;
                transition: opacity 0.4s;
              }

              &:hover {
                svg {
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
    }

    .copyright_text {
      padding: 32px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #667085;
      width: 100%;

      &.set_min_height {
        min-height: 176px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .footer_section {
    max-width: 704px;
    .content_wrap {
      .footer_wrap {
        .footer_items {
          .item.item:not(:last-child) {
            margin-right: 50px;
          }
        }
      }

      .copyright_text.set_min_height {
        min-height: auto !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .footer_section {
    width: 90%;
    .content_wrap {
      .footer_wrap {
        padding-top: 48px;
        padding-bottom: 0;

        .left_content {
          margin-bottom: 48px;
        }

        .footer_items {
          flex-direction: column;

          .item {
            margin-bottom: 48px;
          }
        }
      }

      .copyright_text {
        min-height: auto !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 10px;
        line-height: 1.5;
        max-width: 240px;
      }
    }
  }
}
