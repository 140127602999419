.deleteModal-h2 {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 1.266667em;
  text-align: center;
  letter-spacing: -0.04em;
  color: #101828;
}

.deleteModal-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.42856em;
  text-align: center;
  letter-spacing: -0.04em;
  color: #475467;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 6px;
  flex: 1 0 0;
}

.inputLabel {
  color: #424242;
  font-family: Indivisible;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.56px;
}

.inputContainer .input {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--gray-300, #d6d6d6);
  background: var(--base-white, #fff);

  /* Shadow/xs */
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */

  color: var(--gray-900, #141414);
  /* Text md/Medium */
  font-family: Indivisible;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  transition: all 0.3s ease-in-out;
}

.input:focus {
  border: 1px solid var(--primary-300, #d6bbfb) !important;
  box-shadow:
    0px 0px 0px 4px #f4ebff,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.disabledInputFormLabel {
  color: #a3a3a3 !important;
}
.inputContainer .disabledIputForm {
  background: var(--gray-200, #e5e5e5);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  pointer-events: none;
}

/* Add your existing CSS styles */

.passwordInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.input[type="password"] {
  padding-right: 40px;
  width: 100%;
}

.togglePasswordButton {
  position: absolute;
  top: 25%;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Styles for password visibility */
.input[type="text"] {
  padding-right: 40px;
  width: 100%;
}

.inputContainer-labelRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.inputContainerOptional {
  color: var(--gray-300, #d6d6d6);
  text-align: right;
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
}

.errorMessage {
  color: var(--error-500, #f04438);
  /* Text sm/Regular */
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
}

.inputContainer .inputErrorMode {
  border: 1px solid var(--error-300, #fda29b);

  box-shadow:
    0px 0px 0px 4px #fee4e2,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.accessModal-title {
  color: #141414;
  text-align: center;
  font-family: "Indivisible";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -1.2px;
}

.accessModal-subTitle {
  color: #525252;
  text-align: center;
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
}

.inputContainer .input-wrapper,
.inputContainer .input-wrapper-focus {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--gray-300, #d6d6d6);
  background: var(--base-white, #fff);
}

.inputContainer .input-wrapper-focus {
  border: 1px solid var(--primary-300, #d6bbfb) !important;
  box-shadow:
    0px 0px 0px 4px #f4ebff,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.inputContainer .input-wrapper input {
  width: 100%;
}

.customSelect {
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background: #fff;
  font-family: "Indivisible";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.64px;
  color: #141414;
  transition: all 0.3s ease-in-out;
}

.customSelect:focus {
  border: 1px solid #d6bbfb !important;
  box-shadow:
    0px 0px 0px 4px #f4ebff,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.listitem.empty {
  color: #a459ff;
}
.listitem.noBackgroundChange:hover {
  background-color: transparent !important;
}

.deleteItem-wrapper {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  outline: 1px solid #e5e5e5;
  background: #fff;
  cursor: pointer;
}

.deleteItem-wrapper-selected {
  outline: 2px solid #a459ff;
  background: #f9f5ff;
}

.deleteItem-wrapper .firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteItem-wrapper .firstRow .leftSide {
  display: flex;
  align-items: center;
  gap: 4px;

  overflow: hidden;
}

.deleteItem-wrapper .firstRow .leftSide .mainText {
  color: #424242;
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.56px;

  white-space: nowrap;
}

.mainText.selected {
  color: #53389e !important;
}

.deleteItem-wrapper .firstRow .leftSide .secondaryText,
.deleteItem-wrapper .secondaryText {
  color: #525252;
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}

.secondaryText.selected {
  color: #6941c6 !important;
}

.deleteItem-wrapper .firstRow .emptyCircle {
  width: 16px;
  height: 16px;

  border-radius: 8px;
  border: 1px solid #d6d6d6;
}

.MuiInputBase-root,
.MuiInput-root {
  font-family: "Indivisible" !important;
  font-weight: 500 !important;
}

.MuiInput-input {
  padding: 0 !important;
}
