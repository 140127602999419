.fileName {
  font-family: "Indivisible", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
  color: #a3a3a3;
  white-space: nowrap;
  color: #141414;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.rejectionReason {
  font-family: "Indivisible", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
  white-space: nowrap;
  color: #292929;
}

@media screen and (min-width: 1400px) {
  .fileName {
    max-width: 240px;
  }
}
