@import "variables";

#new-version,
.nv-drawer,
.nv-modal {
  // height: 100%;
  height: 100vh;

  // --utility--classes
  .container {
    // max-width: 1280px;
    margin: auto;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .d-block {
    display: block;
  }

  .d-none {
    display: none;
  }

  .d-flex {
    display: flex;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-column {
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .align-self-center {
    align-self: center;
  }

  .align-self-start {
    align-self: start;
  }

  .align-self-end {
    align-self: end;
  }

  .align-self-stretch {
    align-self: stretch;
  }

  .justify-self-end {
    justify-self: end;
  }

  // --Widths--
  .w-100 {
    width: 100%;
  }

  .w-40 {
    width: 40%;
  }

  .w-max-content {
    width: max-content;
  }

  // --Heights--
  .h-100 {
    width: 100%;
  }

  .min-h-146 {
    min-height: 146px;
  }

  // --Gaps--

  .gap-0 {
    gap: 0px;
  }

  .gap-2 {
    gap: 2px;
  }

  .gap-4 {
    gap: 4px;
  }

  .gap-5 {
    gap: 5px;
  }

  .gap-8 {
    gap: 8px;
  }

  .gap-10 {
    gap: 10px;
  }

  .gap-12 {
    gap: 12px;
  }

  .gap-16 {
    gap: 16px;
  }

  .gap-20 {
    gap: 20px;
  }

  .gap-22 {
    gap: 22px;
  }

  .gap-24 {
    gap: 24px;
  }

  .gap-64 {
    gap: 64px;
  }

  // --Paddings--
  .p-0 {
    padding: 0px;
  }

  .p-8 {
    padding: 8px;
  }

  .p-10 {
    padding: 10px;
  }

  .p-24 {
    padding: 24px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .pl-16 {
    padding-left: 16px;
  }

  .pl-24 {
    padding-left: 24px;
  }

  .pt-0 {
    padding-top: 0px;
  }

  .pt-2 {
    padding-top: 2px;
  }

  .pt-12 {
    padding-top: 20px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pt-24 {
    padding-top: 24px;
  }

  .pt-32 {
    padding-top: 32px;
  }

  .pb-0 {
    padding-bottom: 0px;
  }

  .pb-16 {
    padding-bottom: 16px;
  }

  .pb-24 {
    padding-bottom: 24px;
  }

  .pb-28 {
    padding-bottom: 28px;
  }

  .pb-48 {
    padding-bottom: 48px;
  }

  .py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .py-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .py-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .px-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .px-12 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .px-14 {
    padding-left: 14px;
    padding-right: 14px;
  }

  .px-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  // --Margins--
  .mb-6 {
    margin-bottom: 6px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mb-auto {
    margin-bottom: auto;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  // --Borders--
  .border-none {
    border: none;
  }

  .border-circle {
    border-radius: 50%;
  }

  .border-brand {
    border: 1px solid $brand;
  }

  .border-gray-200 {
    border: 1px solid $gray-200;
  }

  .border-gray-300 {
    border: 1px solid $gray-300;
  }

  .border-top-gray-200 {
    border-top: 1px solid $gray-200;
  }
  .border-top-gray-250 {
    border-top: 1px solid $gray-250;
  }

  .border-bottom-gray-200 {
    border-bottom: 1px solid $gray-200;
  }

  .border-transparent {
    border: 1px solid transparent;
  }

  .border-radius-4 {
    border-radius: 4px;
  }

  .border-radius-8 {
    border-radius: 8px;
  }

  .border-radius-10 {
    border-radius: 10px;
  }

  .border-radius-12 {
    border-radius: 12px;
  }

  .border-radius-16 {
    border-radius: 16px;
  }

  // --Texts--
  .text-align-center {
    text-align: center;
  }

  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color: $white;
  }

  .text-white-important {
    color: $white !important;
  }

  // --Positions--
  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .bottom-0 {
    bottom: 0;
  }

  .left-0 {
    left: 0;
  }

  .right-0 {
    right: 0;
  }

  // --Labels--
  .label-1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-900;
  }

  .label-2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: $gray-600;
  }

  .label-3 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-600;
  }

  .label-4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #000000;
  }

  .label-5-gray-600,
  .label-5-gray-900 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
  }

  .label-5-gray-600 {
    color: $gray-600;
  }

  .label-5-gray-900 {
    color: $gray-900;
  }

  .label-6 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: $black;
  }

  .label-7 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
  }

  .label-sm-medium {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-700;
  }

  .text-xs-semi-bold {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: $white;
  }

  .text-xs-regular {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.04em;
    color: $gray-600;
  }

  .text-xs-medium {
    font-family: "Indivisible", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: $gray-600;
  }

  .text-sm-semi-bold {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-700;
  }

  .text-sm-regular {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-600;
  }

  .text-sm-medium {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-700;
  }

  .text-md-regular {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: $gray-900;
  }

  .text-xl-bold {
    font-family: "Indivisible", "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: $gray-900;
  }

  .text-xl-regular {
    font-family: "Indivisible", "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -0.04em;
    color: $gray-600;
  }

  .display-sm-bold {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.04em;
    color: $gray-900;
  }

  // --Colors--
  .color-white {
    color: $white;
  }

  .color-brand {
    color: $brand;
  }

  .color-gray-400 {
    color: $gray-400;
  }

  .color-gray-500 {
    color: $gray-500;
  }

  .color-gray-600 {
    color: $gray-600;
  }

  .color-gray-700 {
    color: $gray-700;
  }

  .color-gray-900 {
    color: $gray-900;
  }

  .color-warning-700 {
    color: $warning-700;
  }

  .color-blue-700 {
    color: $blue-700;
  }

  .color-success-700 {
    color: $success-700;
  }

  .color-error-700 {
    color: $error-700;
  }

  // --Backgrounds--
  .background-color-white {
    background-color: $white;
  }

  .background-color-gray-50 {
    background-color: #fafafa;
  }

  .background-color-gray-900 {
    background-color: $gray-900;
  }

  .background-color-brand {
    background-color: $brand;
  }

  .background-color-gray-100 {
    background-color: $gray-100;
  }

  .background-color-gray-100:hover {
    background-color: $gray-200;
  }

  .background-color-gray-100-2 {
    background-color: $gray-100-2;
  }

  .background-color-gray-100-2:hover {
    background-color: $gray-200;
  }

  .background-color-warning-50 {
    background-color: $warning-50;
  }

  .background-color-blue-50 {
    background-color: $blue-50;
  }

  .background-color-success-50 {
    background-color: $success-50;
  }

  .background-color-error-50 {
    background-color: $error-50;
  }

  .background-color-violet {
    background-color: #a459ff;
  }

  .avatar-text {
    color: $gray-600;
    font-family: "Indivisible", "Arial" !important;
    font-size: 16px;
    font-weight: 500;
  }

  .big-avatar-text {
    color: $gray-600;
    font-family: "Indivisible", "Arial" !important;
    font-size: 24px;
    font-weight: 500;
  }

  .personal-info-avatar-text {
  }

  //--Box shadow--
  .box-shadow-sm {
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }

  //--Z-Index--
  .z-index-1 {
    z-index: 1;
  }

  .z-index-2 {
    z-index: 2;
  }

  // --typography
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: 0;
    margin-block-start: 0;
  }

  h1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -0.04em;
    color: $gray-900;
    margin: initial;
  }

  h4 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: $gray-900;
  }

  h5 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: $gray-900;
  }

  h6 {
    font-family: "Indivisible", "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $gray-700;
  }

  .fs-14 {
    font-size: 14px;
  }

  .fs-24 {
    font-size: 24px;
  }

  .max-width-40ch {
    max-width: 40ch;
    overflow: hidden;
    white-space: normal;
  }

  .fw-400 {
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .ff-Indivisible {
    font-family: "Indivisible", "Inter", "sans-serif";
  }
  // --components--button--
  .nv-iconButton {
    width: 40px;
    height: 40px;
    background-color: $white;
  }

  .nv-avatar {
    background: var(--gray-100, #f5f5f5);

    &-md {
      max-width: 40px;
      min-width: 40px;
      max-height: 40px;
      min-height: 40px;
    }

    &-lg {
      max-width: 64px;
      min-width: 64px;
      max-height: 64px;
      min-height: 64px;
    }
  }

  .border {
    border-radius: 20px;
  }

  .nv-addButton {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: $white;
    border-radius: 4px;
    background: $brand;
    padding: 10px 16px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    &__iconWrapper {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  .nv-rentYear {
    padding: 2px 8px;
    background: $gray-100;
  }

  .nv-rentYear-2 {
    padding: 0px 8px;
    background: $gray-100;
  }

  .nv-detailsButton {
    padding: 8px 14px;
    background-color: $white;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }

  .nv-button {
    padding: 11px 16px;
  }

  .button-unstyled {
    background: none;
    border: none;
    cursor: pointer;
  }

  .button-unstyled-with-hover {
    background: none;
    border: none;
    cursor: pointer;
  }

  // .button-unstyled-with-hover:hover {
  //     background-color: #f2f4f7;
  // }

  .cursor-default {
    cursor: default;
  }

  // --blocks--header--
  .nv-header {
    position: initial;
    transition: initial;
    background-color: $white;
    padding: 19.5px 0;

    &::before {
      display: none;
    }
  }

  .nv-contentWrapper {
    height: calc(100% - 80px);
  }

  .nv-filterList {
    padding: 0;
    gap: 28px;
    margin-top: 34px;
    margin-block-end: 0;

    &__item {
      padding-bottom: 16px;
      list-style-type: none;
      transition: all 0.5s;
      border-bottom: 3px solid transparent;

      &-selected {
        border-color: $brand;
      }

      &:hover {
        border-color: $brand;
      }

      &::marker {
        display: none;
      }
    }
  }

  .nv-propertiesSection {
    &__leftSide {
      width: 70%;
    }

    &__rightSide {
      width: 30%;
    }
  }

  .nv-propertiesTable {
    border-spacing: 0;
    border-collapse: collapse;

    &__headItem {
      padding: 13px 24px;

      & svg#down {
        margin-left: 7px;
      }
    }

    &__bodyItem {
      padding: 16px 24px;
    }
  }

  .nv-propertyAddress {
    gap: 12px;

    &__img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $gray-600;
    }
  }

  .nv-propertyStatusBadge {
    padding: 4px 12px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;

    &-Review {
      color: $warning-700;
      background-color: $warning-50;
    }

    &-Closed {
      color: $success-700;
      background-color: $success-50;
    }

    &-Archived {
      color: $gray-700;
      background-color: $gray-100;
    }

    &-DocsNeeded {
      color: $error-700;
      background-color: $error-50;
    }

    &-ClearToClose {
      color: $blue-700;
      background-color: $blue-50;
    }
  }

  .nv-borrowerInfo {
    &__iconBox {
      width: 32px;
      height: 32px;
      background-color: $gray-100;
      border-radius: 4px;
    }
  }

  // --pages--Borrower
  .nv-borrowerBanner {
    background-color: $white;
    padding-top: 24px;
  }

  .nv-creditScoreBox {
    padding: 14px 16px 12px;

    &__score {
      font-family: "Indivisible", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.06em;
    }

    &__refreshButton {
      margin-right: 10.5px;
    }
  }

  .nv-scoreRange {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    margin: 0;
    width: 210px;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      background: linear-gradient(90deg, #ff6e6e 0%, #ffa800 29.69%, #62d1a4 65.1%, #a459ff 100%);
      border-radius: 2px;
      height: 12px;
      width: 210px;
    }

    &::-moz-range-track {
      background: linear-gradient(90deg, #ff6e6e 0%, #ffa800 29.69%, #62d1a4 65.1%, #a459ff 100%);
      border-radius: 2px;
      height: 12px;
      width: 210px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none; /* Override default look */
      appearance: none;
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 6px solid #000000;
      border-radius: 24px;
      margin-top: -4px;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 6px solid #000000;
      border-radius: 24px;
      margin-top: -4px;
    }
  }

  .nv-dropdown {
    &__menu {
      top: 100%;
      right: 0;
      margin-top: 4px;
      box-shadow:
        0 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 12px;

      &-visible {
        display: block;
        visibility: visible;
      }

      &-unvisible {
        display: none;
        visibility: hidden;
      }
    }
  }

  .nv-profileMenu {
    &__head {
      padding: 12px 16px;
      display: flex;
      gap: 12px;
    }

    &__body {
      padding: 4px 8px;
    }

    &__imgBox {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      background-color: $gray-900;
      border-radius: 50%;
      overflow: hidden;
    }

    &__profileImg {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &__footer {
      padding: 4px 8px;
    }

    &__link {
      padding: 15px 8px;
      transition: all 0.2s ease;

      &:hover {
        border-radius: 8px;
        background-color: #f9fafb;
      }
    }
  }

  .nv-tooltip {
    &__box {
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;

      &-active {
        display: block;
        visibility: visible;
      }

      &-deactive {
        display: none;
        visibility: hidden;
      }
    }

    &__contentWrapper {
      padding: 8px 12px;
    }

    &__icon {
      margin-bottom: -3px;
    }
  }

  .nv-input {
    outline: none;
    line-height: 22px;
    transition: all 0.3s ease-in-out;

    &:focus {
      border-color: $brand;
    }
  }
}
