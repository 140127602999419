.statusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  mix-blend-mode: multiply;
  border-radius: 4px;
}

.statusContainer-label {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.428571em;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: capitalize;
}
.statusContainer-archived {
  background: #f5f5f5;
}

.statusContainer-active {
  background: #eff8ff;
}
.statusContainer-closed {
  background: #ecfdf3;
}

.statusContainer-label-archived {
  color: #424242;
}

.statusContainer-label-active {
  color: #175cd3;
}

.statusContainer-label-closed {
  color: #027a48;
}
