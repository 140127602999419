.list-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px 8px;
  font-family: "Indivisible", "Arial";
  font-style: normal;
  font-size: 16px;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  align-self: stretch;

  h6 {
    color: #141414;
    font-weight: 500;
  }

  p {
    color: #525252;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-radius: 6px;
    background-color: #f9fafb;
  }
}
