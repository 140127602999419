.buttonStyle {
  transition: none;
  animation: none;
  border-top: 1px solid var(--gray-300, #d6d6d6);
  border-left: 1px solid var(--gray-300, #d6d6d6);
  border-bottom: 1px solid var(--gray-300, #d6d6d6);
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  background: #fff;
  color: var(--gray-700, #424242);
  font-family: "Indivisible";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  &__selected {
    border-top: 1px solid var(--gray-300, #d6d6d6);
    border-left: 1px solid var(--gray-300, #d6d6d6);
    border-bottom: 1px solid var(--gray-300, #d6d6d6);
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-family: "Indivisible";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: var(--primary-700, #6941c6);
    background: var(--primary-100, #f4ebff);
  }

  &__right {
    border-right: 1px solid var(--gray-300, #d6d6d6);
  }
}

.buttonStyle:hover {
  color: var(--primary-700, #6941c6);
  background: var(--primary-100, #f4ebff);
}
