@import "./../../../../variables.scss";

.single-thumb {
  background-color: #e5e5e5;
  height: 24px;
  border-radius: 0%;
  width: 100%;
}

.single-thumb .range-slider__range {
  background-color: #141414;
}

// .single-thumb .range-slider__range[data-active] {
//   border-radius: 0%;
// }

.single-thumb .range-slider__thumb[data-upper] {
  box-sizing: border-box;

  width: 24px;
  height: 24px;

  /* Base/White */

  background: #ffffff;
  /* Gray true / 900 */

  border: 6px solid #141414;
  border-radius: 0%;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}

.sliderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  @media screen and (min-width: $breakpoint-xs) {
    max-width: 848px;
  }
  .megaWrapper {
    justify-content: space-between;
    height: 120px;
    gap: 6px;
    .textInputWrapper {
      .inputSpan {
        &.large {
          height: inherit;
        }
      }
    }
    .inputLabelHold {
      height: 20px;
      margin: 9px 0;
    }
  }
  .firstRow {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (min-width: $breakpoint-xs) {
      height: 160px;
    }
    // align-self: self-end;
    // justify-content: end;
    // text-wrap: wrap;
    // text-align: end;

    .ltv {
      position: absolute;
      right: 0px;
      font-family: "Indivisible";
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.04em;
      align-items: center;
      padding: 8px 0 8px 12px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.04em;
      color: #737373;
      top: 48px;
      @media screen and (min-width: $breakpoint-xs) {
        font-size: 24px;
        line-height: 32px;
        word-wrap: break-word;
        top: 68px;
      }
      @media screen and (max-width: $breakpoint-xxs) {
        top: 8px;
        &.dateTypeContainer {
          top: 48px;
        }
      }
    }
    .dateTypeContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      cursor: pointer;
      .dateType {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;
        border-radius: 6px;
        font-family: "Indivisible";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.04em;
        color: #a3a3a3;
        &.active {
          background: #e5e5e5;
          color: #141414;
        }
        @media screen and (min-width: $breakpoint-xs) {
          font-size: 24px;
          line-height: 32px;
          word-wrap: break-word;
          padding: 10px 14px;
        }
      }
    }
  }
  span {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #a3a3a3;
  }
}
