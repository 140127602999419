.tabsContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
  gap: 1.5em;
}

.tabsContainer-utilities {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 16px;
  flex: none;
}

.tabsContainer-table {
  overflow-y: overlay;
  max-height: 61vh;
}

.MuiTableContainer-root {
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

.MuiTableCell-root {
  padding: 0px !important;
}

/* Table Header text*/

.headerCell-text {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #525252;
  text-wrap: nowrap;
}

/* borrower's Name column cell items*/
.fullNameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.fullNameContainer-fullName {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #141414;
}
.fullNameContainer-email {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #292929;
}

/*Documents column*/
.documentsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.875em;
  gap: 8px;
}
.documentsContainer-number {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
}

/*ActiveLoans */
.activeLoans-text {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #525252;
}

/*TotalLoanCount*/
.totalLoanCount-text {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #525252;
}

/*Date Updated column */

.dateUpdatedContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.dateUpdatedContainer-date {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #141414;
  text-wrap: nowrap;
}

.dateUpdatedContainer-time {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #525252;
  text-wrap: nowrap;
}

/*phone column*/
.phoneContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.phoneContainer-text {
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
  color: #292929;
  text-decoration: none;
  text-wrap: nowrap;
}
