@import "./../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");

.main_search_box {
  position: relative;
  width: 100%;

  &.normal_size {
    margin-top: 5px;
    .input_boxer {
      input {
        padding: 10px;
        font-size: 14px;
        line-height: 20px;
        padding-left: 40px;
        border-radius: 4px;
      }

      svg {
        width: 18px;
        height: 18px;
        top: 11px;
      }
    }
  }

  .input_boxer {
    width: 100%;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
    border-radius: 4px;

    input {
      width: 100%;
      padding: 15px;
      border: none;
      outline: #a459ff;
      padding-right: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #000000;
    }

    svg {
      position: absolute;
      left: 12px;
      top: 14px;
    }
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 6px;
    flex: 1 0 0;
  }

  .addr_icon_wrap {
    margin-top: 16px;
    gap: 1rem;
    .addr_icon_container {
      background-color: #f4ebff;
      padding: 10px 12px;
      border-radius: 4px;
    }
    .selected_address {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.5px;
      font-family: "Inter", sans-serif;
      color: #344054;
      display: flex;
      gap: 0.5rem;
      justify-content: flex-start;
      max-width: 300px;
      span {
        font-size: 14px;
        color: #98a2b3;
      }
    }

    .edit_field {
      cursor: pointer;
      .input_boxer {
        padding: 10px 12px;
        svg {
          position: static;
        }
      }
    }
  }
}

.suggestions_boxer {
  position: fixed;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  margin-top: 2px;
  width: 100%;
  z-index: 5;

  .suggestion_item {
    width: 100%;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #ebebeb;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #000000;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #ebebeb;
    }
  }
}

.closure_input {
  @include flex-row();

  button {
    @include flex-row();
    margin-left: 10px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    min-width: 85px;
    height: 40px;
    justify-content: flex-end;

    svg {
      margin-left: 4px;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

.search_suggestion_wrapper {
  .suggestions_wrapper {
    position: fixed;
    z-index: 100;
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    max-width: 400px;
    // animation: slideBtT 0.2s 1;

    .suggestion_item {
      padding: 10px 12px;
      background-color: #fff;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #000000;
      cursor: pointer;
      transition: background 0.3s;
      box-shadow: inset 0px -1px 0px #ebebeb;

      &:hover {
        background-color: #ebebeb;
      }
    }
  }
}

.calendar_wrapped {
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.192);
  min-width: 280px;
  max-width: 280px;
  top: 0;
  right: 0;
  animation: slideBtT 0.3s 1;
  z-index: 999;

  .react-calendar__navigation {
    @include flex-row();
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    @include flex-row();

    abbr {
      text-decoration: none !important;
      font-size: 15px;
    }
  }

  .react-calendar__month-view__weekdays {
    @include flex-row();
  }

  button {
    background-color: transparent;
    color: #000;
    border: none;
    text-align: center;
    padding: 8px !important;

    &:hover {
      background-color: #0a5dff2e;
    }

    &.react-calendar__tile--active {
      background-color: $purple;
      color: #fff;
    }
  }
}

.error_message {
  position: absolute;
  font-size: 14px;
  color: $red;
  font-weight: 300 !important;
  float: right;
  margin: 4px 0 6px;
  animation: errorAnime 0.4s 1;
  min-width: 100%;
  text-align: right;
}

.input_validation_message {
  font-size: 14px;
  color: $red;
  font-weight: 300 !important;
  margin: 4px 0 6px;
  animation: errorAnime 0.4s 1;
  min-width: 100%;
  text-align: right;
}

.is_input_error {
  .radio_item {
    label {
      &:before {
        border-color: $red !important;
        opacity: 1;
      }
    }
  }

  &.money_formatted_input_basic {
    border-color: $red !important;
  }

  &.input_box {
    input,
    div[class*="control"] {
      border-color: $red !important;
    }
  }

  &.main_search_box {
    .input_boxer {
      border-color: $red !important;
    }
  }

  .button_group_selector {
    border-color: $red !important;
  }
}

.input_box {
  margin-bottom: 20px;
  position: relative;

  &.money {
    svg {
      position: absolute;
      left: 12px;
      // bottom: 50%;
      // transform: translateY(50%);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.dropdown {
    &.smaller_version {
      transform: scale(0.9);
    }

    &.skinny {
      margin-bottom: 0;

      div[class*="control"] {
        border: 0 !important;
        min-height: 18px;
        font-size: 15px;
        box-shadow: none;

        div {
          margin: 0;
          padding: 0;
        }

        div[class*="Input"] {
          margin: 0;
          padding: 0;
        }
      }

      div[class*="IndicatorsContainer"] {
        display: none;
      }
    }

    span[class*="indicatorSeparator"] {
      display: none;
    }

    svg {
      transform: scale(0.7) translateX(5px);
    }

    div {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;

      &[class*="Input"] {
        color: #000;
      }

      &[class*="ValueContainer"] {
        padding-right: 0;

        [class*="multiValue"] {
          display: none;
          background-color: transparent;

          &:first-child {
            display: block;
          }

          div[role="button"] {
            display: none;
          }
        }
      }

      // &[class*='IndicatorsContainer'] {
      //     display: none;
      // }

      &[class*="control"] {
        border-radius: 3px;
        border: 1px solid #ebebeb;
        padding: 1px 0;
        border-width: 1px;
        min-height: 40px;
        height: 40px;
        padding: 0;

        div[class*="ValueContainer"] {
          height: 40px;
          margin-top: -2px;
        }

        div[class*="indicatorContainer"] {
          padding: 5px;
        }
      }

      &[class*="placeholder"] {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        color: #b2b2b2;
      }
    }

    .icon {
      z-index: 9;
      background-color: #fff;
      margin-top: -3px;

      svg {
        transform: none;
      }
    }
  }

  &.phone_no {
    .PhoneInput {
      //background: #f7f7f7;
      border: 1px solid #ebebeb;
      box-sizing: border-box;
      border-radius: 3px;
      // padding: 10px;
      position: relative;

      .PhoneInputCountry {
        width: 55px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fff;
        border-right: 1px solid #ebebeb;

        .PhoneInputCountryIcon {
          width: 100%;
          height: 100%;
          padding: 10px;
          border: none;
          box-shadow: none;
          background-color: #fff;
          margin-right: 10px;
        }

        .PhoneInputCountrySelectArrow {
          position: absolute;
          right: 7px;
          top: 16px;
        }
      }

      input {
        height: 40px;
        border: 1px solid transparent;
        background: transparent;
        outline: none;
        margin-left: 55px;
        width: calc(100% - 85px);
        transition: border-color 0.3s;

        &:focus {
          border-color: $focus-purple;
        }
      }
    }
  }

  &.icon_only_calendar {
    margin-bottom: 0;
    cursor: pointer;

    .icon_only_default_wrap {
      @include flex-row();

      .label {
        margin-bottom: 0;
        margin-right: 5px;
      }

      .icon {
        position: relative;
        top: 0;
        right: 0;
      }
    }

    .calendar_wrapped {
      min-width: 250px;
    }
  }

  .input_row {
    @include flex-row();
    justify-content: space-between;

    &.unit_position_right {
      input {
        order: 2;
        border-radius: 0px 3px 3px 0px;
      }

      .unit {
        border-radius: 3px 0px 0px 3px;
        border-right: 0px;
      }
    }

    input,
    textarea {
      &::placeholder,
      &::-webkit-input-placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: -0.5px;
        color: #b2b2b2;
      }
    }

    .unit {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #000000;
      padding: 10px;
      border: 1px solid #ebebeb;
      border-radius: 0px 3px 3px 0px;
      transition: all 0.3s;
      margin-left: -1px;
    }

    &.unit_available {
      gap: 0.25rem;
    }
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    width: fit-content;
  }

  .icon {
    position: absolute;
    bottom: 0;
    left: 8px;
    max-width: 20px;
    height: 40px;
    object-fit: contain;
    object-position: center;
  }

  .input_entry {
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    width: 100%;
    padding: 10px;
    outline: none;
    // border: 2px solid transparent;
    transition: all 0.3s;
    box-sizing: border-box;

    &:placeholder-shown,
    &:-internal-autofill-selected {
      background-color: #fff !important;
      border: 1px solid #ebebeb;
    }

    &:focus {
      border-color: $focus-purple;
    }
  }

  textarea {
    height: 150px !important;
    resize: none;
    font-family: inherit;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 10px;
  }

  .money_formatted_input_basic,
  .date_formatted {
    padding-left: 24px;
  }

  .input_search {
    padding-left: 40px;
  }
}

.radio_box {
  width: 100%;
  @include flex-row();
  flex-wrap: wrap;
  justify-content: flex-start;

  &.disabled {
    pointer-events: none;
    .radio_item {
      label {
        &:after {
          border-color: #b2b2b2;
        }
      }
    }
  }

  &.unavailable {
    pointer-events: none;
  }

  .radio_items_wrap {
    width: 100%;
    @include flex-row();
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .radio_item {
    &:not(:last-child) {
      margin-right: 15px;
    }

    input {
      display: none;

      &:checked {
        & ~ label {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    label {
      margin-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      cursor: pointer;
      position: relative;
      margin-left: 22px;

      &:before,
      &:after {
        @include pseudo_abs();
        width: 12px;
        height: 12px;
        border-radius: 50%;
        left: -21px;
        top: 1px;
        border: 2px solid #ebebeb;
        transition: opacity 0.2s;
      }

      &:after {
        width: 5px;
        height: 5px;
        border: 5px solid #0a5dff;
        opacity: 0;
      }
    }
  }
}

.switch_box {
  position: relative;
  margin-right: 5px;
  @include flex-row();

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.5px;
    color: #000000;
  }

  .switch_show {
    background-color: #b2b2b2;
    border-radius: 81px;
    width: 38px;
    height: 24px;
    padding: 3px;
    cursor: pointer;
    position: relative;
    margin-left: 8px;
    transition: background 0.3s;

    .circle {
      background: #ffffff;
      box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
      width: 18px;
      border-radius: 50%;
      height: 18px;
      position: absolute;
      left: 3px;
      transition: left 0.2s;
    }
  }

  input {
    display: none;

    &:disabled {
      & ~ .switch_show {
        cursor: not-allowed;
        background-color: #b2b2b2;
      }
    }

    &:checked {
      & ~ .switch_show {
        background: #0a5dff;
        .circle {
          left: calc(50% - 2px);
        }
      }
    }
  }
}

.check_box {
  position: relative;
  margin-right: 5px;
  @include flex-row();

  &:hover {
    .checkbox_dupl {
      border-color: $focus-purple;
    }
  }

  input {
    display: none;

    &:disabled {
      & ~ label {
        cursor: not-allowed;
      }

      &:checked {
        & ~ .checkbox_dupl {
          background-color: #b2b2b2;
          border-color: #b2b2b2;
        }
      }
    }

    &:checked {
      border-color: $focus-purple;
      & ~ .checkbox_dupl {
        background-color: $purple;
        border-color: $focus-purple;

        svg {
          transform: translate(0px) scale(1);
        }
      }
    }

    &:indeterminate::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border: solid white;
      border-width: 2px 0 0 0;
      position: absolute;
      top: 9px;
      left: 5px;
    }
  }

  .checkbox_dupl {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 3px solid #ebebeb;
    @include flex-row();
    transition: all 0.2s;
    overflow: hidden;
    cursor: pointer;
    margin-right: 8px;

    svg {
      transition: all 0.3s;
      transform: translate(0, 13px) scale(0);
    }
  }

  label {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
}

.item_selector {
  position: relative;
  z-index: 99;

  &.is_transparent {
    float: left;

    &:not(.fully_transparent) {
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
    }

    .label {
      background-color: transparent;
      border: none;
      padding: 0;
      z-index: 2;
      position: relative;
    }

    .values_wrap {
      padding-right: 0;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
      z-index: 1;
    }
  }

  .label {
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    padding: 7px 12px;
    max-width: 120px;
    float: right;
    @include flex-row();
    cursor: pointer;
    transition: border-color 0.3s;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: #000000;

    &.square {
      width: 32px;
      height: 32px;
      padding: 0;
      @include flex-row();

      .default_icon {
        display: none;
      }

      svg {
        margin: 0;
      }
    }

    &:hover,
    &.focused {
      border-color: $purple;
    }

    svg {
      margin-right: 8px;
      margin-top: 2px;
    }
  }

  .values_wrap {
    width: 240px;
    position: absolute;
    top: calc(100% + 5px);
    background-color: #fff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 3px;
    right: 0;
    animation: slideTtB 0.1s 1;

    .value_item {
      cursor: pointer;
      padding: 8px 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      color: #000000;
      box-shadow: inset 0px -1px 0px #ebebeb;
      background-color: #fff;
      transition: background 0.3s;

      &:hover {
        background-color: rgb(243, 242, 242);
      }

      &:last-child {
        box-shadow: none;
      }
    }
  }
}

.button_group_selector {
  border: 1px solid #ebebeb;
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.02));
  border-radius: 4px;
  width: auto;
  @include flex-row();

  button {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #101828;
    padding: 10px 16px;
    border: none;
    box-shadow: none;
    width: 100%;
    border-radius: 4px;
    @include flex-column();

    .color_dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 9px;
    }

    &:not(:last-child) {
      border-right: 1px solid #ebebeb;
      border-left: 1px solid #ebebeb;
    }

    &:hover {
      background-color: #f7f7f7;
    }

    &.selected {
      color: $purple;
      background: #f4ebff;
      border: 1px solid #d0d5dd;
      span {
        color: $purple;
      }
    }
    span {
      font-size: 13px;
      font-weight: 500;
      color: #475467;
    }
  }

  .radio_box {
    .radio_item {
      input {
        display: none;
      }

      label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.5px;
        cursor: pointer;
        position: relative;

        &:before,
        &:after {
          @include pseudo_abs();
          width: 8px;
          height: 8px;
          border-radius: 50%;
          left: -21px;
          top: 1px;
          border: 2px solid #ff6e6e;
          background: #ff6e6e;
          transition: opacity 0.2s;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .main_search_box {
    .addr_icon_wrap {
      flex-direction: row !important;
    }
  }

  .closure_input {
    button {
      justify-content: flex-start;
      padding-left: 0;
      margin-left: 0;
    }
  }

  .button_group_selector {
    button {
      &:not(:last-child) {
        border-left: none;
        border-right: none;
        border-top: none;
      }
    }
  }
}

@media screen and (max-width: calc($breakpoint-xxs + 120px)) {
  .selected_address {
    flex-direction: column;
    align-items: flex-start;
  }
}
