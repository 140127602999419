@import './../../variables.scss';

.program_wrap {
  min-height: 50vh;
  width: 100%;
  padding-top: $mainnav-height;

  &.rental_wrap {
    .hero_section .content_wrap .text_wrap p {
      max-width: 480px;
    }
  }

  .hero_section {
    position: relative;
    margin: auto;
    .content_wrap {
      //padding: 0 60px;
      .text_wrap {
        @include flex-column();
        align-items: flex-start;
        min-height: calc(#{$min-height} - #{$mainnav-height});
        max-width: 50%;
        height: 100%;

        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 72px;
          line-height: 74px;
          letter-spacing: -4.5px;
          color: #101828;
          margin-bottom: 24px;
          max-width: 608px;
        }

        p {
          max-width: 608px;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #475467;
          margin-bottom: 48px;
          letter-spacing: 0;
        }
      }
    }

    .hero_image {
      min-height: calc(#{$min-height} - #{$mainnav-height});
      position: absolute;
      right: 0;
      top: 0;
      width: 80%;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 60px solid transparent;
        border-top: calc(#{$min-height} - #{$mainnav-height}) solid #fff;
        z-index: 2;
        right: 52%;
      }

      img {
        max-width: 100%;
        height: 100%;
        min-height: calc(#{$min-height} - #{$mainnav-height});
        object-fit: cover;
        object-position: right;
        animation: imageZoomIn 3.5s 1 cubic-bezier(0, 0.46, 0, 0.95) both;
        z-index: 1;
        position: absolute;
        right: 0%;
        width: 100%;
        animation-fill-mode: forwards !important;
      }
    }
  }

  .details_section_wrap {
    margin: 96px auto;
    max-width: 1280px;
    .content_wrap {
      @include flex-row();
      align-items: flex-start;
      align-items: stretch;

      .detail_item_wrap {
        flex-grow: 1;
        width: 100%;
        padding: 24px 32px;
        border-left: 1px solid #62d1a4;

        &:first-child:not(:last-child) {
          border: none;
        }

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          color: #101828;
        }

        .description {
          margin-top: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #475467;
        }

        ul {
          margin-top: 32px;
          list-style-type: none;

          li {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: #475467;
            margin-bottom: 20px;
            position: relative;

            &::before {
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #62d1a4;
              position: absolute;
              left: -26px;
              top: 9px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .program_wrap {
    .hero_section {
      max-width: 704px;
      .content_wrap {
        //padding: 0 30px;
        .hero_image{
          img{
            width: 62%;
          }
        }
      }
    }
    .details_section_wrap{
      max-width: 704px;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .program_wrap{
    .hero_section {
      .content_wrap {
        .text_wrap {
          h1 {
            font-size: 62px;
            line-height: 64px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .program_wrap {
    overflow: hidden;
    padding-top: 73px;
    .hero_section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: calc(100vh - #{$mainnav-height});
      width: 90%;
      .content_wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //padding: 0 16px;
        .text_wrap {
          max-width: 100%;
          min-height: auto;
          padding-bottom: 64px;

          h1 {
            font-size: 56px;
            line-height: 56px;
            letter-spacing: -3px;
            margin-bottom: 16px;
          }

          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
          }
        }
      }

      .hero_image {
        width: 100%;
        height: 100%;
        min-height: auto;
        position: relative;

        img {
          min-height: auto;
          height: 100%;
          width: 100%;
          position: relative;
        }

        &:before {
          display: none;
        }
      }
    }

    .details_section_wrap {
      margin: 80px 0;
      width: 90%;
      .content_wrap {
        flex-direction: column;

        .detail_item_wrap {
          border: none;
          padding: 0px;

          &:not(:last-child) {
            margin-bottom: 64px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xxs) {
  .program_wrap{
    .hero_section {
      .content_wrap {
        .text_wrap {
          h1 {
            font-size: 50px;
            line-height: 55px;
          }
        }
      }
    }
  }
}
