#fluid-scroll-content {
  transition: transform 0.1s;
}

#fluid-scroll {
  box-shadow: 12px -160px 8px -10px rgb(31 73 125 / 20%), -12px -160px 8px -10px rgb(31 73 125 / 20%);
}

.input_box_view {
  @include flex-row();
  min-height: calc(100vh);
  justify-content: center;
  position: relative;
  overflow: hidden;

  .image_box_wrapper {
    width: calc(50vw + 120px);
    max-height: calc(100vh);
    height: calc(100vh);
    position: relative;
    transition: all 0.5s;
    position: absolute;
    right: 0;
    top: 0;
    animation: widthFromLeft 0.8s 1;
    @include flex-column();
    justify-content: space-between;
    padding-bottom: 71px;
    padding-top: 54px;
    padding-right: 51px;
    padding-left: 92px;
    align-items: flex-start;

    &:before {
      @include pseudo-abs();
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
      z-index: 2;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      float: right;
    }
  }

  .input_box_wrapper {
    min-height: calc(100vh - #{$mainnav-height});
    @include flex-row();
    flex: 1;
    max-width: calc(600px);

    .box {
      width: 100%;
      max-width: 400px;
    }
  }
}

.reading_page {
  background-color: #fff;

  .banner {
    text-align: center;
    padding: calc(72px + #{$mainnav-height}) 0 72px 0;
    z-index: 1;
    position: relative;
    animation: bgColorSync 0.6s infinite;

    &:before {
      @include pseudo_abs();
      background-color: #f2eff4;
      width: 100%;
      height: 100%;
      top: 0%;
      transition: top 0.3s, box-shadow 0.3s;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
      animation: slideFullTtB 1.5s 1;
    }

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 52px;
      letter-spacing: -2px;
      font-feature-settings: 'ordn' on;
    }
  }

  .content_wrap {
    margin-top: 72px;
    // max-width: 805px;
    padding-bottom: 50px;

    a {
      color: #624ce0 !important;
      text-decoration: none !important;
    }

    p,
    li {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.5px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      padding-left: 25px;
      li {
        margin-bottom: 0px;
      }
    }

    h1 {
      font-family: 'Indivisible', 'Arial' !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.5px;
      margin-bottom: 32px;
      margin-top: 26px;
    }

    h2 {
      font-family: 'Indivisible', 'Arial' !important;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.5px;
      margin-bottom: 10px;
    }
  }
}

.strike_wrap {
  div {
    float: left;
  }
  .strike {
    position: relative;
    display: block;
    float: left;

    &:before {
      @include pseudo-abs();
      width: 100%;
      height: 2px;
      background-color: #000;
      top: 56%;
      animation: widthIncrease 0.5s 1;
    }
  }
}

.customers_wrap {
  z-index: 1;
  position: relative;

  .hear_from {
    display: none !important;
    padding: 92px 0;
    background-color: #000;

    .content_wrap {
      max-width: 1024px;

      .x2 {
        flex: 1;
      }

      h1 {
        width: 100%;
        max-width: 342px;
        margin-bottom: 0;
      }

      .common_title {
        width: 100%;
        max-width: 528px;
        margin-bottom: 16px;
      }

      .sub_title {
        opacity: 0.5;
      }
    }
  }
}

.zoom_banner_anime {
  animation: bgZoomIn 3.5s 1 cubic-bezier(0, 0.46, 0, 0.95) both;
}

.tabs_wrap {
  @include flex-row();
  justify-content: space-between;
  max-width: 1080px;
  margin-left: 50%;
  transform: translate(-50%);
  width: 100%;
  position: relative;

  &[selectedTab='2'] {
    &:before {
      left: calc(33.33% + 20px);
    }
  }

  &[selectedTab='3'] {
    &:before {
      left: calc(66.66% + 25px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    background: #f2eff4;
    height: 100%;
    left: 0;
    top: 0;
    width: calc(33.33% - 15px);
    z-index: -1;
    border-radius: 4px;
    transition: left 0.5s;
  }

  .tab_item {
    cursor: pointer;
    flex: 1;
    max-width: 330px;
    width: 100%;
    padding: 16px 32px;
    border-radius: 4px;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.5px;
      color: #b2b2b2;
      margin-bottom: 8px;
      transition: background 0.3s;
      position: relative;
      margin: 0;

      &:before {
        content: attr(data-text);
        letter-spacing: -0.5px;
        margin-bottom: 8px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(279.95deg, #624ce0 10.56%, #a459ff 82.49%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    .subtitle {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.5px;
      color: #b2b2b2;
      transition: color 0.3s;
    }

    &.active {
      // background: #f2eff4;

      .title {
        &:before {
          opacity: 1;
        }
      }

      .subtitle {
        color: #000000;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .zoom_banner_anime {
    animation: bgZoomInAuto 3.5s 1 cubic-bezier(0, 0.46, 0, 0.95) both;
  }

  .customers_wrap {
    .hear_from {
      text-align: center;
    }
  }

  .tabs_wrap {
    &:before {
      width: calc(33.33% - 0px);
    }

    &[selectedTab='2'] {
      &:before {
        left: calc(33.33% - 0px);
      }
    }

    &[selectedTab='3'] {
      &:before {
        left: calc(66.66% + 0px);
      }
    }

    .tab_item {
      padding: 8px;
      text-align: center;

      .title {
        font-size: 13px;

        &::before {
          left: auto;
        }
      }

      .subtitle {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .zoom_banner_anime {
    animation: bgZoomInAuto 3.5s 1 cubic-bezier(0, 0.46, 0, 0.95) both;
  }

  #fluid-scroll-content {
    transition: none;
  }
}
