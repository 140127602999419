@import './../../variables.scss';

header {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: background 0.8s;
  background-color: white;
  &:before {
    @include pseudo_abs();
    background-color: #fff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    top: -100%;
    transition: top 0.3s, box-shadow 0.3s;
    // box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }

  &.scrolled_header {
    backdrop-filter: blur(2px);

    &::before {
      top: 0;
      transition: top 0.8s;
      // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    .content_wrap {
      height: 70px;

      .links_wrap {
        .main_link {
          color: #000;

          &:before {
            background-color: #000;
          }
        }
      }
    }

    .logo_wrap {
      position: relative;
      svg {
        path {
          fill: #000;

          &.icon_logo {
            fill: #a459ff;
          }
        }
      }
    }
  }

  &.white_background {
    &:before {
      top: 0;
    }
  }

  &.black_version {
    .content_wrap {
      .links_wrap {
        .main_link {
          color: #000;

          &:before {
            background-color: #000;
          }
        }
      }
    }

    .logo_wrap {
      position: relative;
      svg {
        path {
          fill: #000;

          &.icon_logo {
            fill: #a459ff;
          }
        }
      }
    }

    &.scrolled_header {
      .content_wrap {
        .main_link {
          text-shadow: none;
        }

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &.black_white_version {
    background-color: #fff;

    .content_wrap {
      .links_wrap {
        .main_link {
          color: #fff;

          &:before {
            background-color: #fff;
          }
        }
      }
    }
  }

  &.transparent_version{
    background-color: transparent;
    .content_wrap {
      .links_wrap {
        .main_link {
          color: #fff;
        }
      }
      &.transparent_scrolled{
        .links_wrap {
          .main_link {
            color: #000;
          }
        }
      }
      .white.square.hamburger{
        &::before{
          background-image: none;
        }
      }
    }
    .logo_wrap {
      position: relative;
      bottom: 4px;
      svg {
        path {
          fill: #fff;
        }
      }
      &.transparent_scrolled{
        svg {
          path {
            fill: #000;
            &.icon_logo {
              fill: #a459ff;
            }
          }
        }
      }
    }
  }

  .content_wrap {
    height: 70px;
    @include flex-row();
    justify-content: space-between;
    margin-bottom: 0;
    transition: height 0.8s, box-shadow 0.8s;

    .links_wrap {
      @include flex-row();
      justify-content: flex-end;

      .sub_links_wrap {
        display: none;
      }

      .main_link {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #fff;
        text-decoration: none !important;
        margin-right: 48px;
        position: relative;
        cursor: pointer;
        @include flex-column();
        transition: color 0.3s;

        &:hover {
          color: #a459ff;
          &:before {
            width: 100%;
          }
        }

    
      }

      .buttons_wrap {
        margin-left: 24px;

        a {
          &:not(:first-child) {
            margin-left: 24px;
          }
        }
      }
    }

    .logo_wrap {
      svg {
        path {
          transition: fill 0.3s;
        }
      }
    }

    .hamburger {
      @include flex-row();
      display: none;
      height: 32px;
      width: 32px;
      padding: 7px;
      border: none;
      padding: 0;
      background-color: transparent;

      svg {
        animation: zoomIn 0.7s 1;
      }
    }
  }
}

.financing_wrap {
  &:hover {
    .mega_menu_main_wrap {
      top: 0px;
      pointer-events: all;
      opacity: 1;

      .mega_menu {
        @for $i from 1 through 5 {
          .link_card:nth-child(#{$i}) {
            * {
              animation-duration: #{$i/10 + 0.4}s !important;
            }
          }
        }

        .link_card {
          .image_wrap {
            animation: zoomIn 0.8s 1;
          }

          .title {
            animation: slideRtLOpac 0.8s 1;
          }

          .sub_text {
            animation: slideRtLOpac 0.8s 1;
          }
        }
      }
    }
  }
}

.mega_menu_main_wrap {
  padding-top: 40px;
  position: absolute;
  transition: top 0.3s, opacity 0.3s;
  top: -40px;
  pointer-events: none;
  opacity: 0;

  .mega_menu {
    top: 0;
    display: block;
    z-index: 5;
    width: 100%;
    min-width: 336px;
    overflow: hidden;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    .link_card {
      text-decoration: none !important;
      @include flex-row();
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      padding: 28px 26px;
      transition: background 0.3s;

      &:hover {
        background-color: #eaecf0;
      }

      &:not(:first-child):before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 24px);
        height: 1px;
        left: 12px;
        background-color: #eaecf0;
      }

      &:first-child {
        padding-top: 24px;
      }

      &:last-child {
        padding-bottom: 24px;
        border-bottom: none;
      }

      .image_wrap {
        margin-right: 18px;
      }

      .info_wrap {
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #101828;
          margin-bottom: 4px;
        }

        .sub_text {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md){
  header{
    .content_wrap {
      width: 100%;
      max-width: 704px;
      margin: auto;
      //padding: 0 60px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .main_link {
    span {
      display: none;
    }
  }

  .mega_menu_main_wrap {
    // display: none !important;
    top: 0;
    opacity: 1;
    padding-top: 0;
    overflow-y: auto;
    width: 100%;
    z-index: 5;
    position: relative;

    .mega_menu {
      min-width: 100%;
      border: none !important;

      .link_card {
        padding: 18px 26px;
        float: left;
        width: 100%;

        .image_wrap {
          animation: none !important;
        }

        .title {
          animation: none !important;
        }

        .sub_text {
          animation: none !important;
        }
      }
    }
  }

  header {
    &.scrolled_header {
      &:before {
        opacity: 1;
      }
    }

    &:before {
      top: 0;
      background-color: #fff;
    }

    &.transparent_version{
      &:before {
        background-color: transparent;
      }
      &.scrolled_header {
        &:before {
          background-color: #fff;
        }
      }
    }

    .content_wrap {
      width: 90%;
      margin: auto;
      //padding: 0 16px;
      height: $mainnav-mob-height !important;

      .sub_links_wrap {
        @include flex-column();
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 20px;
      }

      .links_wrap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: #fff;
        margin-top: $mainnav-mob-height;
        flex-direction: column;
        justify-content: flex-start;
        height: 0;
        transition: height 0.8s, padding 0.8s;
        overflow: hidden;
        padding: 0px 30px;

        .buttons_wrap {
          margin-left: 0;
        }

        &.is_mob_visible {
          height: calc(100vh - $mainnav-mob-height);
          padding: 40px 30px;
          overflow: auto;
          .main_link,
          .sub_link,
          .buttons_wrap {
            opacity: 1;
          }
        }

        .buttons_wrap {
          order: 3;
          width: 100%;
          @include flex-column();
          align-items: stretch;
          transition: opacity 1s;
          opacity: 0;

          a {
            flex: 1;
            min-width: calc(50% - 8px);

            &:not(:first-child) {
              margin-left: 0;
              margin-top: 1.2rem;
            }

            button {
              width: 100%;
            }
          }
        }

        .main_link,
        .sub_link {
          order: 2;
          margin: 0;
          width: 100%;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          letter-spacing: -0.5px;
          font-feature-settings: 'ordn' on;
          text-align: left;
          align-items: flex-start;
          transition: opacity 1s;
          opacity: 0;
          // color: #fff !important;
          -webkit-tap-highlight-color: transparent;

          &:before {
            display: none;
          }

          &.greyed {
            color: #000 !important;
            order: 1;
          }
        }

        .sub_link {
          margin-bottom: 10px;
          color: #333;
          font-size: 20px;
          text-decoration: none !important;
        }
      }

      .hamburger {
        display: flex;
      }
    }
  }
}