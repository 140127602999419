@import './../../variables.scss';

.bg_image {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  object-fit: cover;
  top: 70px;
  transform: translate(275px, 0px) rotate(130deg) !important;
}

.landing_page {
  padding-top: 73px;
  min-height: 100vh;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  position: relative;
  .content_wrap{
    //padding: 20px 30px;
  }

  .hero_section, .site_main_details_section, .site_stats_section, .where_we_lend_section{
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  .hero_section {
      position: relative;
      .content_wrap {
        //padding: 0 60px;
        display: flex;
        justify-content: space-between;
      .text_wrap {
        @include flex-column();
        align-items: flex-start;
        min-height: calc(#{$min-height} - #{$mainnav-height});
        max-width: 50%;
        height: 100%;

        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 72px;
          line-height: 74px;
          letter-spacing: -4.5px;
          color: #101828;
          margin-bottom: 24px;
          max-width: 608px;
        }

        p {
          max-width: 608px;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #475467;
          margin-bottom: 48px;
          letter-spacing: 0;
        }
      }
      .hero_image {
        margin-top: 4rem;
        min-height: calc(#{$min-height} - #{$mainnav-height});
        width: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
          max-width: 80%;
          height: 100%;
          min-height: calc(#{$min-height} - #{$mainnav-height});
          object-fit: contain;
          object-position: right;
          //animation: imageZoomIn 3.5s 1 cubic-bezier(0, 0.46, 0, 0.95) both;
          z-index: 1;
          width: 100%;
          //animation-fill-mode: forwards !important;
        }
      }
    }
  }

  .site_main_details_section {
    .content_wrap {
      .flex_box {
        .details_items_wrapper {
          width: 100%;
          @include flex-row();
          align-items: flex-start;

          .detail_item {
            width: 50%;
            padding: 56px;

            .icon {
              margin-bottom: 64px;
              width: 40px;
            }

            .title {
              font-style: normal;
              font-weight: 600;
              font-size: 30px;
              line-height: 38px;
              color: #101828;
            }

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #475467;
              margin-top: 16px;
              max-width: 265.5px;
              flex: none;
              order: 1;
              align-self: stretch;
              flex-grow: 0;
            }
          }
        }
        .phone_image_wrap{
          max-width: 500px;
          img{
            width: 100%;
          }
        }
      }
    }
  }

  .site_stats_section {
    margin-top: 50px;
    //padding: 0 30px;

    .content_wrap {
      background: #202d59;
      border-radius: 16px;
      padding: 64px;

      .flex_box {
        justify-content: space-around;
      }

      .stat_item {
        color: white;
        @include flex-column();
        font-weight: 400;
        text-align: center;
        font-size: 60px;
        line-height: 72px;
        min-width: 33.33%;
        font-feature-settings: 'ordn' on;

        p {
          font-size: 18px;
          line-height: 28px;
          margin-top: 12px;
          color: #eaecf0;
        }
      }
    }
  }

  .where_we_lend_section {
    margin: 90px 0;
    .heading {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #101828;
      margin-bottom: 40px;
    }

    svg {
      max-height: min(80vh, 800px);
      min-height: 450px;
      width: 100%;
    }

    .legend_wrap {
      min-width: 150px;

      .legend_item {
        @include flex-row();
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: #000000;
        margin-bottom: 16px;
        padding-left: 24px;
        position: relative;

        &.green {
          &::before {
            background-color: #62d1a4;
          }
        }

        &:before {
          @include pseudo-abs();
          top: calc(50% - 5px);
          width: 12px;
          height: 12px;
          background-color: #ebebeb;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: calc($breakpoint-md)){
  .landing_page {
    .content_wrap {
      //padding: 20px 60px;
    }
    .hero_section {
      .hero_image {
        img {
          max-width: 55% !important;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .landing_page {

    .hero_section, .site_main_details_section, .site_stats_section, .where_we_lend_section{
        max-width: 704px;
    }

    .hero_section{
      .content_wrap{
        //padding: 0 30px;
      }
    }

    .site_main_details_section {
      .content_wrap {
        .flex_box {
          .phone_image_wrap {
            order: 2;
            margin-top: 40px;
          }

          .details_items_wrapper {
            order: 1;
            margin-top: 1rem;
            flex-wrap: wrap;
            gap: 80px;
            .detail_item {
              width: 100%;
              padding: 0;

              .icon {
                margin-bottom: 30px;
              }

              .title {
                margin-bottom: 4px;
              }
              p {
                margin-top: 0;
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .site_stats_section {
      .content_wrap {
        padding: 64px 0;

        .flex_box {
          align-items: flex-start;
          .stat_item {
            &:not(:last-child) {
              margin-bottom: 32px;
            }
          }
        }
      }
    }

    .where_we_lend_section {
      .content_wrap {
        .heading {
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.02em;
          margin-bottom: 20px;
        }

        svg {
          min-height: 235px;
        }
      }

      .legend_wrap {
        width: 100%;
        @include flex-row();
        justify-content: space-around;

        .legend_item {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-smmd){
  .landing_page {
    .where_we_lend_section {
      .content_wrap{
        .flex_box{
          flex-direction: column-reverse;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .landing_page{
    .hero_section{
      .content_wrap{
        .hero_image{
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .hero_image {
    img {
      max-width: 55% !important;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .bg_image {
    height: 50%;
  }
  .landing_page .site_stats_section .content_wrap .flex_box {
    align-items: center;
  }

  .landing_page{
    .hero_section, .site_main_details_section, .site_stats_section, .where_we_lend_section{
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .landing_page {
    .hero_section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: calc(100vh - #{$mainnav-height});

      .content_wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //padding: 0 16px;
        .text_wrap {
          max-width: 100%;
          min-height: auto;
          padding-bottom: 64px;

          h1 {
            font-size: 56px;
            line-height: 56px;
            letter-spacing: -3px;
            margin-bottom: 16px;
          }

          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 16px;
          }
        }
        .hero_image {
          width: 100%;
          height: 100%;
          min-height: auto;
          position: relative;
          display: flex;
          justify-content: center;
          margin: auto;
  
          img {
            min-height: 500px;
            height: 100%;
            width: 100%;
            position: relative;
          }
  
        }
      }

    }
  }
}

@media screen and (max-width: $breakpoint-xxs){
  .landing_page {
    // .main_hero_section{
    //   .content_wrap{
    //     .flex_box{
    //       .flex_column{
    //         .main_heading{
    //           font-size: 50px;
    //           line-height: 55px;
    //         }
    //       }
    //     }
    //   }
    // }
    .hero_section {
      .content_wrap {
        .text_wrap {
          h1 {
            font-size: 50px;
            line-height: 55px;
          }
        }
      }
    }
  }
}