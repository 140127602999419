@import "./../../../../src/variables.scss";

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: visible;
  width: 230px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -20px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.megaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
  height: 128px;
  @media screen and (min-width: $breakpoint-xs) {
    height: 116px;
    max-width: 908px;
  }
  .inputLabel {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #737373;
    &.hide {
      visibility: hidden;
    }
  }
  .textInputWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    flex: none;
    width: 100%;
    color: #d6d6d6;
    font-size: 30px;
    font-family: "Indivisible";
    font-weight: 600;
    line-height: 38px;
    word-wrap: break-word;
    .placeholder {
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      display: flex;
      align-items: center;
      letter-spacing: -0.04em;
      color: #d6d6d6;
      &.large {
        font-size: 49px;
        line-height: 72px;
      }
      &.s1 {
        color: #f4ebff;
      }
    }
    .caret {
      width: 4px;
      height: 42px;
      background-color: #424242;
      &.large {
        height: 52px;
      }
    }
    .inputSpan {
      margin: 0px;
      padding: 0px;
      display: flex;
      min-width: 1px;
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      align-items: center;
      letter-spacing: -0.04em;
      color: #141414;
      border: none;
      white-space: nowrap;
      width: 100%;
      line-height: 38px;
      border-bottom: 1px solid #d6d6d6;
      border-radius: 0;
      &:focus {
        border-bottom: 1px solid #424242;
      }

      &.large {
        font-size: 49px;
        line-height: 72px;
        @media screen and (min-width: $breakpoint-xs) {
          font-size: 72px;
          font-weight: 600;
          line-height: 90px;
          word-wrap: break-word;
          height: 132px;
        }
      }
      &.notValid {
        color: #b42318;
      }
      &.disabled {
        color: #a3a3a3;
      }

      &::placeholder {
        color: #d6d6d6;
      }
      &:focus::placeholder {
        color: #f4ebff;
      }
      @media screen and (min-width: $breakpoint-xs) {
        color: #141414;
        font-family: "Indivisible";
        font-weight: 600;
        word-wrap: break-word;
      }
    }
    .eyePasswordIcon {
      cursor: pointer;
      height: 100%;
      display: flex;
      border-bottom: 1px solid;
      align-items: center;
      &.focused {
        border-bottom: 1px solid #424242;
      }
    }
  }

  .caret {
    width: 4px;
    height: 56px;
    background-color: #424242;
  }

  .secondaryText {
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #a3a3a3;
    @media screen and (min-width: $breakpoint-xs) {
      line-height: 32px;
      word-wrap: break-word;
    }
  }
  .secondaryTextHold {
    padding: 12px 0;
    height: 12px;
  }
  .validationError {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px 2px 6px;
    gap: 4px;
    background: #fef3f2;
    mix-blend-mode: multiply;
    border-radius: 4px;
    span {
      font-family: "Indivisible";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */

      text-align: center;
      letter-spacing: -0.04em;
      white-space: nowrap;
      /* Error/700 */

      color: #b42318;
    }
  }
  .validationErrorHold {
    margin: 11px 0;
  }
}

.onboardingButton {
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  flex: none;
  align-self: stretch;
  cursor: pointer;
  @media screen and (max-height: $breakpoint-height) {
    font-size: 18px;
    font-family: "Indivisible";
    font-weight: 600;
    line-height: 28px;
    word-wrap: break-word;
    padding: 16px 24px;
  }
  @media screen and (min-width: $breakpoint-xs) {
    padding: 40px 48px;
    @media screen and (max-height: $breakpoint-height) {
      padding: 24px 32px;
      font-size: 24px;
      line-height: 32px;
    }
  }
  &.addStroke {
    svg {
      stroke: #141414;
      width: 32px;
      height: 32px;
      @media screen and (min-width: $breakpoint-xs) {
        width: 48px;
        height: 48px;
      }
    }
  }

  &.active {
    border: 2px solid #62d1a4;
    svg {
      stroke: #62d1a4;
    }
  }
  .buttonText {
    display: flex;
    font-family: "Indivisible";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    letter-spacing: -0.04em;
    color: #141414;
    flex: none;
    flex-grow: 1;
    @media screen and (min-width: $breakpoint-xs) {
      font-size: 24px;
      line-height: 32px;
      word-wrap: break-word;
    }
  }
}
