// --Color--Variables
$white: #fff;
$black: #000;
$brand: #a459ff;
$gray-100: #f2f4f7;
$gray-100-2: #f5f5f5;
$gray-200: #eaecf0;
$gray-250: #e0e0e0;
$gray-300: #d0d5dd;
$gray-400: #a3a3a3;
$gray-500: #667085;
$gray-600: #525252;
$gray-700: #344054;
$gray-900: #141414;

$warning-50: #fffaeb;
$warning-700: #b54708;
$blue-50: #eff8ff;
$blue-700: #175cd3;
$success-50: #ecfdf3;
$success-700: #027a48;
$error-50: #fef3f2;
$error-700: #b42318;
$green: #32d583;

// --Size--Variables
$size-20: 20px;
$size-40: 40px;
