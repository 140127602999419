.searchContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  width: 400px;
}

.searchContainer-input {
  border: 0px solid;
  padding: 0px;
  width: 348px;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #737373;
}
