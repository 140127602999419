.settingsDropDown--container {
  z-index: 5;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  background: var(--base-white, #fff);
  padding: 4px 8px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.settingsDropDown--item {
  padding: 11px 8px;
  cursor: pointer;
}

.settingsDropDown--item:hover {
  border-radius: 6px;
  background-color: #f9fafb;
}

.settingsDropDown--item-p {
  color: #344054;
  /* Text sm/Medium */
  font-family: "Inter";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.428571em;
  letter-spacing: -0.04em;
}
