.dropDownButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px 2px 8px;
  gap: 4px;
  background: #f5f5f5;
  mix-blend-mode: multiply;
  border-radius: 4px;
  justify-content: flex-start;
  font-family: "Indivisible";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: -0.04em;
  color: #424242;
}
.cursorPointer {
  cursor: pointer;
}
.cursorDisabled {
  cursor: not-allowed;
}
.dropDownButton svg path {
  stroke: #737373;
}

ul,
li {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.listwrapper {
  display: flex;
  max-height: 268px;
  background-color: white;
  border: 1px solid #e5e5e5;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  z-index: 5;
  overflow-y: auto;
}

.listcontainer {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 6px;
  flex-grow: 1;
}

.listcontainer-full {
  display: flex;
  width: 695px;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 6px;
  flex-grow: 1;
}

.templatelistcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 4px 8px;
}

.large-width {
  width: 360px;
}
.listcontainer ul {
  margin: 0;
}

.listitem-template {
  display: flex;
  padding: 10px 80px 10px 8px;
  align-self: stretch;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #101828;
  justify-content: flex-start;
}

.listitem-template:hover {
  border-radius: 6px;
  background-color: #f9fafb;
}

.listitem-bigger {
  display: flex;
  padding: 10px 0px 10px 8px;
  align-self: stretch;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5em;
  letter-spacing: -0.04em;
  color: #101828;
  justify-content: flex-start;
}

.listitem-bigger:hover {
  border-radius: 6px;
  background-color: #f9fafb;
}

.customInput {
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.archiveColor {
  color: #101828;
}

.dropDownSpan {
  display: flex;
  text-align: left;
  word-wrap: break-word;
}

/* bulk input */

.bulk-input {
  color: #a459ff !important;
  text-wrap: nowrap;
}

.bulk-input:focus {
  color: #141414 !important;
}

.input-placement--main {
  color: var(--gray-900, #141414) !important;
  /* Text sm/Medium */
  font-family: "Indivisible" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important ;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: -0.56px !important;
}

.input-placement--main::placeholder {
  color: var(--gray-500, var(--gray-500, #737373)) !important;
  font-family: "Indivisible" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: -0.56px !important;
}

.input-placement--secondary {
  color: var(--gray-600, var(--gray-600, #525252));
  /* Text sm/Regular */
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
}

.searchDropDown-minWidth {
  min-width: 350px;
}

.searchDropdown-minWidth-InputDropdown {
  max-width: 140px;
}

.searchDropdown-minWidth-full {
  min-width: 100%;
}

.searchDropDown-listItem {
  justify-content: space-between !important;
  align-items: center;
}

.searchDropDown-loanId {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 4px;
  border: 1.5px solid var(--gray-600, #525252);

  color: var(--gray-700, #424242);
  text-align: center;
  font-family: "Indivisible";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.48px;
}

.listitem.inviteUser {
  color: #a459ff;
}

.listitem.empty {
  color: #a459ff;
}
.listitem.noBackgroundChange:hover {
  background-color: transparent !important;
}
