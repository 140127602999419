.chevron__to__right {
    transform: rotate(180deg);
}

.chevron__to__bottom {
    transform: rotate(270deg);
}

.chevron {
    width: 32px;
    height: 32px;
}