@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/vft5rcz.css");
@import "./variables.scss";
@import "./animations.scss";
@import "./utils/shared/styles.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  opacity: 0; // Initially hidden
  background: #eaecf0;
  border-radius: 4px;
  transition: opacity 0.2s; // Smooth transition when it appears and disappears
}

::-webkit-scrollbar-thumb:hover {
  background: #e5e5e5;
}

.nv-document-management:hover::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:active {
  opacity: 1; // Appears when scrolling is happening
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: "Indivisible", "Arial" !important;

  &.overflow_hidden {
    overflow: hidden;
  }
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Indivisible", "Arial", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #fafafa;

  * {
    box-sizing: border-box;
  }
}

// #root {
//   background-color: #fff;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// }

// #root:not(:has(.onboarding_wrapper)){
//   max-width: 1650px;
//   margin: auto;
// }

#old-version {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#old-version:not(:has(.onboarding_wrapper)) {
  max-width: 1650px;
  margin: auto;
}

.content_wrap {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  //margin-left: 50%;
  //transform: translate(-50%);
  //padding: 0 30px;
  box-sizing: border-box;
}

.page_wrapper {
  overflow: hidden;
}

.error_text {
  color: #f51818;
  font-size: 13px;
  margin-top: 4px;
}

.error_text_hold {
  height: 22px;
}

.flex_box {
  @include flex-row();
  align-items: flex-start;

  &.space_between {
    justify-content: space-between;
  }

  &.align_start {
    align-items: flex-start;
  }

  &.align_center {
    align-items: center;
  }

  &.justify_start {
    justify-content: flex-start;
  }

  &.flex_wrap {
    flex-wrap: wrap;
  }

  &_column {
    @include flex-column();
    align-items: flex-start;
  }

  .x2 {
    width: 50%;
    @include flex-row();

    &.adjust_padding {
      width: calc(50% - 7.5px);
    }

    &.align_start {
      align-items: flex-start;
    }

    &.align_end {
      align-items: flex-end;
    }

    &.justify_start {
      justify-content: flex-start;
    }

    &.justify_end {
      justify-content: flex-end;
    }

    &.flex_column {
      flex-direction: column;
    }
  }

  .x3 {
    width: calc(33.33% - 10px);
  }

  .x4 {
    width: calc(50% - 12px);
  }
}

.full_width {
  max-width: 100% !important;
  width: 100% !important;
}

.relative {
  position: relative;
}

.linkWithoutUnderline {
  text-decoration: none;
}

.z-max {
  z-index: 2 !important;
}

.w-50 {
  width: 50%;
}

// button,
.button {
  font-family: "Indivisible", "Arial", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  background: #ffffff;
  text-decoration: none;
  border: none;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 12px 20px;
  letter-spacing: -0.5px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  // transition: background 0.3s, transform 0.1s, opacity 0.3s;
  // transition-timing-function: ease-in-out;
  border: 1px solid #ebebeb;
  text-align: center;
  opacity: 1;
  position: relative;
  overflow: hidden;
  // -webkit-font-smoothing: antialiased;

  &::before {
    content: "";
    transition-timing-function: ease-in-out;
    position: absolute;
    left: -150%;
    top: 0;
    height: 100%;
    width: 50%;
    min-width: 80px;
    z-index: 1;
    background-image: linear-gradient(
      to left,
      transparent,
      rgba(255, 255, 255, 0.274),
      rgba(255, 255, 255, 0.274),
      transparent
    );
    transform: skew(-0.5rad);
  }

  &[disabled] {
    cursor: default;
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover:not(disabled) {
    // transform: scale(1.005);

    &:before {
      left: 150%;
      transition: left 0.8s;
    }

    // &:active {
    //   transform: scale(0.95);
    // }
  }

  &.small {
    padding: 12px 24px;
  }

  &.purple {
    background: #a459ff;
    color: #fff;
    border: none;
  }

  &.blue {
    background: #202d59;
    color: #fff;
    border: none;
  }

  &.loader_button {
    @include flex-row();
    flex-wrap: nowrap;
    white-space: nowrap;

    &[disabled] {
      background-color: #ebebeb;
    }

    svg {
      margin-right: 5px;
      animation: rotate 0.8s infinite linear;
    }
  }

  &.bv3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.5px;
    overflow: visible;
    border: none;
    text-decoration: none !important;
    padding: 22px 24px;
    transition: transform 0.2s;

    &.purple {
      &:after {
        background-color: #a459ff;
      }
    }

    &:before {
      width: 100%;
      height: 12px;
      left: 0;
      top: calc(100% - 4px);
      z-index: -1;
      background-color: #202d59;
      transform: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-image: none;
      transition: bottom 0.3s;
    }

    &:after {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      content: "";
      position: absolute;
      left: 0;
      top: calc(100% - 15px);
      width: 100%;
      height: 15px;
      background-color: #fff;
    }

    &:hover:not(disabled) {
      // transform: scale(1.005);

      &:before {
        transition:
          bottom 0.3s,
          height 0.4s;
        left: 0;
      }

      &:active {
        // transform: none;

        &:before {
          height: 0;
        }
      }
    }
  }
}

h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -2px;
  font-feature-settings: "ordn" on;
  margin: 0;
  margin-bottom: 24px;
}

h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -1px;
  font-feature-settings: "ordn" on;
}

h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.title_text {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: -2px;
}

.common_title {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -1px;
  color: #000000;
}

.sub_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: #000000;
}

p {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin: 0;

  &.bigger {
    font-size: 18px;
    line-height: 22px;
  }

  &.larger {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -2px;
  }

  &.smaller {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  &.tiny {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: -0.5px;
  }

  &.para {
    font-size: 14px;
    line-height: 16px;
  }
}

.white_text {
  color: #ffffff;
}

.grey_text {
  color: #b2b2b2;
}

.placholder_text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #b2b2b2;
}

.normal_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -1px;
  margin-bottom: 16px;
}

.small_text {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #000000;
}

.hidden {
  display: none;
}

.small_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.circle_info_title {
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -2px;
  font-feature-settings: "ordn" on;
  color: #000000;
  margin-bottom: 32px;
}

.text_box_wrap {
  @include flex-column();
  align-items: flex-start;

  &.x2_column {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .text_box {
      width: calc(50% - 50px);
      padding-top: 40px !important;

      &:nth-last-child(-n + 2) {
        border-bottom: 0;
      }
    }
  }

  &.x3_column {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .text_box {
      width: calc(33.33% - 50px);
      padding-top: 40px !important;
      border-bottom: none;
      width: 100%;
      max-width: 310px;
    }
  }

  .text_box {
    border-bottom: 4px solid #ebebeb;
    padding: 40px 0;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.visible {
  //visibility: visible;
  display: block;
}

.unvisible {
  //visibility: hidden;
  display: none;
}

.my-auto {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-6 {
  margin-top: 6px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.w-50 {
  width: 50%;
}

.b-0 {
  bottom: 0 !important;
}

.h-90 {
  height: 90%;
}

.h-100 {
  height: 100%;
}

.font-indivisible {
  font-family: "Indivisible", "Arial", sans-serif !important;
}

.font-inter {
  font-family: "Inter", "Arial", sans-serif !important;
}

.link {
  color: $brand-purple;
  font-weight: 500;
}

.button:hover:not(disabled):active,
button:hover:not(disabled):active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  &.mainPart {
    -webkit-transform: none;
    transform: none;
  }
}

.mainPart {
  .button:hover:not(disabled):active,
  button:hover:not(disabled):active {
    -webkit-transform: none;
    transform: none;
  }
}

@media screen and (max-width: $breakpoint-md) {
  html {
    body {
      .circle_info_title {
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -2px;
        margin-bottom: 16px;
        text-align: left;
      }

      .normal_heading {
        text-align: left;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -2px;
      }

      .text_box_wrap {
        .text_box {
          border-bottom: 0;
        }
      }

      h1 {
        font-size: 32px;
        line-height: 39px;
        letter-spacing: -2px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  html {
    body {
      .flex_box:not(.response_stays) {
        flex-direction: column;

        .x2 {
          width: 100%;
        }
      }
    }
  }

  // .content_wrap {
  //   padding: 0 16px;
  // }

  button,
  .button {
    &.bv3 {
      font-size: 14px;
      line-height: 18px;
      padding: 12px 16px;
      font-weight: 500;

      &:before {
        height: 8px;
        top: calc(100% - 4px);
      }

      &:after {
        top: calc(100% - 10px);
        height: 8px;
      }

      &:hover:not(disabled) {
        // transform: scale(1.005);

        &:before {
          transition:
            bottom 0.3s,
            height 0.4s;
          left: 0;
        }

        &:active {
          &:before {
            height: 4px;
          }
        }
      }
    }
  }
}

a:focus-visible,
button:focus-visible {
  outline: 1px solid gray;
}
