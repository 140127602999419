@import './../../variables.scss';

.start_up_main_wrap {
  padding-top: 60px;
  background: #f7f7f7;

  .title_text {
    margin-bottom: 40px;
  }

  .animation_glare_layout {
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 600px;
    position: absolute;
  }

  .input_box_wrapper {
    flex-direction: column !important;
    position: relative;

    .box {
      padding: 40px;
      background: #ffffff;
      z-index: 1;

      &.new_to {
        padding: 0;
        justify-content: space-between;
        background: none;
        margin-top: 26px;

        p {
          max-width: 313px;
        }
      }

      .login_input {
        margin-bottom: 40px;

        .input_box {
          margin-bottom: 5px;
          margin-top: 20px;
        }
      }
      .title{
        padding: 0 1em;
      }

      .login_wrap {
        @include flex-row();
        justify-content: space-between;

        .forgot_password {
          @include flex-row();
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.5px;
          color: #000000;
          cursor: pointer;

          &:hover {
            span {
              margin-left: 2px;
            }
          }

          span {
            transition: margin 0.3s;
            img {
              margin-top: 2px;
            }
          }
        }
      }

      .buttons_wrap {
        float: right;

        button {
          &.back {
            margin-right: 16px;
          }
        }

        &.verify_accnt {
          margin-top: 40px;
        }
      }
    }

    .scroller_signer {
      height: 100%;
      width: 100%;
      margin-bottom: 40px;
    }

    &.register_form {
      margin-top: -60px;

      &.google_signed {
        .register_box {
          top: -100vh;
          opacity: 0;
        }

        .google_box {
          top: 0;
          opacity: 1;

          .buttons_wrap {
            margin-top: 40px;
          }
        }
      }

      &.verify_form {
        .register_box {
          top: -100vh;
          opacity: 0;
        }

        .verify_box {
          top: 0;
          opacity: 1;

          .input_box {
            &[disabled] {
              cursor: default;
              opacity: 0.2;
              pointer-events: none;
            }
          }

          p {
            &.we_have {
              margin-top: 8px;
              margin-bottom: 24px;
            }
          }

          .wrong_code {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.5px;
            color: #ff6e6e;
            margin-top: 5px;
          }

          .resend_code {
            @include flex-row();
            width: fit-content;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.5px;
            color: #b2b2b2;
            justify-content: flex-start;
            margin-top: 1em;
            transition: color 0.4s;
            cursor: default;

            &.available {
              color: #000;
              cursor: pointer;

              span {
                filter: grayscale(0);
              }

              &:hover {
                span {
                  margin-left: 2px;
                }
              }
            }

            span {
              transition: margin 0.3s;
              filter: grayscale(2);

              img {
                margin-top: 2px;
              }
            }
          }
        }
      }

      .anime_box {
        @include flex-row();
        padding-top: 60px;
        position: absolute;
        top: 5%;
        left: 0;
        width: 100%;
        height: 100%;
        transition: top 0.6s, opacity 0.4s;

        &.create {
          button {
            margin-bottom: 24px;

            &.sign_in {
              @include flex-row();
            }
            svg {
              margin-right: 4px;
            }
          }

          .email_verify {
            color: #20c933;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.5px;
            margin-top: 8px;
          }

          .input_box {
            margin-top: 24px;
          }

          .use_email {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.5px;
          }

          .agree {
            margin-top: 24px;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.5px;
            span {
              a {
                margin-left: 2px;
                margin-right: 2px;
                color: #0a5dff;
                text-decoration: none;
              }
            }
          }
        }
      }

      .verify_box,
      .google_box {
        top: 100vh;
        opacity: 0;
      }

      .input_box {
        margin-bottom: 0;
      }

      .small_title {
        margin-bottom: 8px;
        color: #000000;
      }

      .search_box_wrap {
        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.5px;
        }
      }
    }

    &.login_form{
      &.successBox{
       align-items: center!important; 
      }
    }

    &.credit_score_form {
      .title_text {
        margin-bottom: 0px;
      }

      p {
        &.we_need {
          margin-top: 16px;
          margin-bottom: 40px;
        }
      }

      .check_box {
        justify-content: flex-start;
      }

      .run_credit {
        margin-top: 40px;
        align-items: flex-end;
        justify-content: space-between;

        .input_box {
          margin-bottom: 0px;
          margin-right: 24px;

          .input_entry {
            padding-left: 8px;
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }

        button {
          flex: 1;
          width: 100%;
          word-break: keep-all;
        }
      }

      .credit_notification_wrap {
        margin-top: 40px;

        .next_button {
          margin-top: 40px;
          float: right;

          button {
            &.back {
              margin-right: 16px;
            }
          }
        }

        .head_title {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
        }

        .credit_score_box {
          border: 1px solid #ebebeb;
          box-sizing: border-box;
          padding: 16px;
          width: 100%;
          @include flex-column();
          justify-content: space-between;

          .topper {
            width: 100%;
            @include flex-row();
            justify-content: space-between;
            align-items: flex-start;

            .left {
              .value {
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                letter-spacing: -2px;
                color: #ebebeb;

                .score_type {
                  font-weight: 400;
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: -0.5px;
                  color: #b2b2b2;
                  margin-top: -2px;
                  margin-bottom: 2px;
                }
              }
            }

            .right {
              @include flex-row();
              justify-content: flex-end;

              button {
                margin-left: 5px;
                display: flex;
                align-items: center;
                padding: 7px;
              }
            }
          }

          .botter {
            width: 100%;

            .gradient_strip {
              background: linear-gradient(
                90deg,
                #ff6e6e 0%,
                #ffa800 29.69%,
                #20c933 65.1%,
                #0a5dff 100%
              );
              height: 8px;
              width: 100%;
              position: relative;

              .circle_dark {
                position: absolute;
                width: 12px;
                height: 12px;
                background: #ffffff;
                border: 4px solid #000000;
                border-radius: 24px;
                top: -2px;
                transition: left 0.4s;
              }
            }
          }
        }
      }

      .credit_button_wrap {
        margin-top: 40px;

        .skip {
          @include flex-row();
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: -0.5px;
          color: #b2b2b2;
          cursor: pointer;
          justify-content: flex-start;
          color: #000;
          transition: color 0.4s;
          text-decoration: none !important;

          &:hover {
            span {
              margin-left: 2px;
            }
          }

          span {
            transition: margin 0.3s;
            img {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }

  .image_box_wrapper {
    padding: 0;
    width: calc(50vw + 160px);

    &:before {
      display: none;
      opacity: 0;
    }

    .carousel-root {
      width: 100%;
      height: 100%;

      &.black-dots {
        .carousel .control-dots .dot {
          background: #fff;
        }
      }

      .carousel {
        height: 100%;

        .control-dots {
          bottom: 35px;
          @include flex-row();
          justify-content: flex-start;
          padding-left: 55px;

          .dot {
            height: 8px;
            width: 8x;
          }
        }

        .slider-wrapper {
          height: 100%;

          ul {
            height: 100%;

            li {
              height: 100%;

              &.selected {
                .slide_item {
                  p {
                    opacity: 1;
                    transform: translateY(0px);
                  }
                }
              }

              .slide_item {
                color: #ffffff;
                p {
                  opacity: 0;
                  transform: translateY(50px);
                  transition: opacity 1s, transform 0.8s;

                  &.larger {
                    transition-delay: 300ms;
                  }
                  &.smaller {
                    transition-delay: 450ms;
                    width: 100%;
                    max-width: 556px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .start_up_main_wrap {
    .animation_glare_layout {
      margin-right: 20px;
      margin-top: 25%;
    }

    .input_box_wrapper {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      padding: 20px;

      .new_to {
        flex-direction: row;
        justify-content: space-between;

        .title {
          padding-right: 15px;
        }
      }

      &.register_form {
        .anime_box {
          padding: 20px;
        }
      }
    }

    .image_box_wrapper {
      display: none;
    }
  }
}
