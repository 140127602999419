.nv-document-management {
  width: 100%;
  padding: 0 5em;
  margin: 0 auto;
  max-width: 1600px;
  min-width: 1340px;
  background-color: white;
  // overflow-y: overlay;
}
.nv-document-management:hover::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:active {
  opacity: 1; // Appears when scrolling is happening
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3em 0em 0em 0em;
  gap: 2em;
  background-color: white;
  padding-bottom: 10px;
}

.mainContainer__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1.5em;
  align-self: stretch;
}

.mainContainer__header--top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 1.5em;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.mainContainer__header--top h1 {
  font-family: "Indivisible" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 2.25rem !important;
  line-height: 2.75rem !important;
  letter-spacing: -0.04em !important;
  color: #141414 !important;
}

.h1__wrapper {
  display: flex;
  flex-grow: 1;
}

.loanList--address1 {
  color: var(--gray-900, #141414);
  /* Text sm/Medium */
  font-family: "Indivisible";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
}
