// $colors: (
//   red: #e14e43,
//   green: #62d1a4,
//   yellow: rgb(237, 207, 115),
//   blue: #2e90fa,
// );

// @each $color-name, $color-code in $colors {
//   .border-#{$color-name} {
//     border: 2px solid $color-code;
//     border-radius: 100%;
//   }
// }

.iconWrapper {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

// $colors: (
//   red: #e14e43,
//   green: #62d1a4,
//   yellow: rgb(237, 207, 115),
//   blue: #2e90fa,
// );

// @each $color-name, $color-code in $colors {
//   .border-#{$color-name} {
//     border: 2px solid $color-code;
//     border-radius: 20px;
//   }
// }
