@import "./../../variables.scss";

.onboardingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: relative;
  background: #ffffff;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  .onboardingMainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px 24px;
    gap: 8px;
    flex: none;
    order: 1;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 76px;
    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: 0;
    }
    label {
      font-size: 12px;
      line-height: 18px;
      @media screen and (min-width: $breakpoint-xs) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .nextButton {
      background-color: $brand-purple;
      span {
        color: #ffffff;
      }
      svg {
        stroke: #ffffff;
      }
    }
  }
}

.deleteModal-p {
  span {
    font-weight: 600;
  }
}

// .loading{
//   margin: 10em auto;
// }

// .onboarding_wrapper {
//   padding-bottom: 50px;
//   background-color: #f9fafb;

//   .main_banner_wrap {
//     height: 90px;
//     width: 100%;
//     @include flex_row();
//     position: relative;
//     overflow: hidden;
//     background: linear-gradient(
//       90.78deg,
//       #a459ff 59.82%,
//       #5e6eff 81.17%,
//       #32d583 103.09%
//     );

//     .title {
//       font-family: "Inter", sans-serif;
//       font-style: normal;
//       font-weight: 700;
//       font-size: 28px;
//       line-height: 34px;
//       display: flex;
//       align-items: center;
//       letter-spacing: -2px;
//       color: #FFFFFF;
//     }
//   }

//   .form_wrapper {
//     width: 100%;
//     max-width: 800px;
//     margin-left: 50%;
//     transform: translate(-50%);
//     margin-top: 40px;
//     .buttons_wrap {
//       @include flex_row();
//       justify-content: flex-end;
//       width: 100%;
//       margin-top: 32px;
//       float: left;
//       position: relative;
//       z-index: -1;
//       gap: 12px;
//     }

//     input[type="number"]::-webkit-outer-spin-button,
//     input[type="number"]::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     }

//     .forms_switch_box {
//       width: 100%;
//       float: left;

//       .form_container {
//         width: 100%;
//         float: left;
//         position: relative;

//         &.name_form {
//           z-index: 1;
//         }

//         &:not(:last-child) {
//           margin-bottom: 30px;
//         }

//         .box_title {
//           float: left;
//           width: 100%;
//         }

//         .heading {
//           margin-bottom: 20px;
//           font-style: normal;
//           font-weight: 700;
//           font-size: 18px;
//           font-family: 'Inter', sans-serif;
//           line-height: 22px;
//           letter-spacing: -0.5px;
//           color: #000000;

//           svg {
//             margin-right: 20px;
//           }
//         }

//         .helper-text{
//           position: relative;
//           color: #475467;
//           font-size: 13px;
//           font-family: "Inter", sans-serif;
//           //top: -1rem;
//         }

//         .form_box {
//           background: #ffffff;
//           border-radius: 4px;
//           padding: 25px 20px;
//           float: left;
//           width: 100%;
//           border: 1px solid #EAECF0;
//           box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 0px 1px 2px rgb(16 24 40 / 6%);
//           button{
//             font-family: 'Inter', sans-serif!important;
//           }

//           .title {
//             color: #344054;
//             font-size: 14px;
//             font-weight: 600;
//             margin-bottom: 6px;
//             font-family: 'Inter', sans-serif;
//           }

//           .lived_in {
//             margin-top: 24px;

//             .question {
//               width: 100%;
//             }

//             .radio_box {
//               justify-content: flex-end;
//             }
//           }

//           .debt_inputs{
//             @include flex();
//             align-items: flex-start;
//             gap: 24px;
//             & > div:nth-child(1){
//               flex: 3;
//             }
//             & > div:nth-child(2){
//               flex: 7;
//             }
//           }

//           .question_row {
//             &.in_show_animate {
//               animation: slideBtT 0.4s 1;
//             }

//             .unit-sign{
//               font-family: "Inter", sans-serif;
//               position: absolute;
//               bottom: 50%;
//               transform: translateY(50%);
//               font-size: 14px;
//               font-style: normal;
//               font-weight: 500;
//               line-height: 14px;
//             }

//             .question {
//               font-weight: 600;
//               font-size: 14px;
//               line-height: 17px;
//               display: flex;
//               align-items: center;
//               letter-spacing: -0.5px;
//               color: #000000;
//             }

//             &:not(:last-child) {
//               margin-bottom: 24px;
//             }

//             &.date_input {
//               width: 50%;
//               .input_box {
//                 width: calc(100% - 12px);
//               }
//             }

//             &.citizen {
//               width: 50%;
//               .input_box {
//                 position: relative;
//                 bottom: -6px;
//                 width: calc(100% - 12px);
//               }
//             }

//             &.name {
//               width: 50%;
//               .input_box{
//                 width: calc(100% - 12px);
//               }
//             }

//             &.contact {
//               width: 50%;
//               .input_box{
//                 width: calc(100% - 12px);
//               }
//               &:nth-child(2){
//                 .input_box{
//                   position: relative;
//                   bottom: 5px;
//                 }
//               }
//             }

//             &.credit {
//               width: 50%;
//               .input_box{
//                 width: calc(100% - 12px);
//               }
//             }

//             &.dropdown{
//               .input_box{
//                 width: 50%;
//               }
//             }

//             &.bank{
//               .input_box{
//                 & > svg{
//                   position: absolute;
//                   bottom: 50%;
//                   transform: translateY(50%);
//                   z-index: 1;
//                   left: 15px;
//                 }
//                 .banksList{
//                   div[class$="-control"]{
//                       padding-left: 40px;
//                   }
//                 }
//               }
//             }

//             .input_entry{
//               &::placeholder{
//                 color: #b8b8b8;
//               }
//             }
//             .purchase_inputs {
//               display: flex;
//               justify-content: space-between;
//               margin-bottom: 1rem;
//               .input_box {
//                 width: 100%;
//               }

//               .edit_field,
//               .back_field {
//                 background-color: #fff !important;
//                 //border: 1px solid #ebebeb;
//                 width: 5%;
//                 margin-bottom: 20px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//               }
//             }
//           }

//           &.condition {
//             .input_box {
//               .input_row {
//                 input {
//                   width: 264px;
//                 }
//               }
//             }
//           }

//           .input_wrap {
//             .input_box {
//               width: calc(50% - 12px);
//             }
//           }

//           .search_input {
//             .input_box {
//               width: 100%;
//               width: calc(50% + 12px);
//               margin-right: 24px;
//             }
//           }

//           .small_text {
//             margin-right: 4px;
//             cursor: pointer;
//           }

//           .table_box {
//             border: 1px solid #ebebeb;
//             border-radius: 4px;
//             padding: 12px 16px;
//             margin-bottom: 8px;
//             width: 100%;
//             float: left;

//             .title {
//               font-weight: 600;
//               font-size: 14px;
//               line-height: 17px;
//             }

//             .radio_box {
//               .radio_items_wrap {
//                 justify-content: flex-end;
//               }
//             }

//             .loan_amount {
//               margin-top: 24px;

//               .input_box {
//                 width: 100%;
//               }
//             }

//             &.loan_box_wrap {
//               padding-right: 16px;
//               height: 100%;
//               min-height: 160px;
//               float: left;

//               p {
//                 float: right;
//                 margin-top: 8px;
//                 margin-bottom: 16px;
//               }
//             }
//           }
//         }

//         .icon_wrap {
//           svg {
//             margin-right: 16px;
//           }
//         }

//         &.value_of_prop {
//           margin-bottom: 0px;

//           .para {
//             margin-top: 16px;
//             margin-bottom: 32px;
//             float: left;
//             width: 100%;
//           }
//         }
//       }
//     }
//   }

//   .scan_id_wrapper {
//     background: #202d59;
//     box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.08);
//     border-radius: 4px;
//     padding: 16px;
//     margin-bottom: 32px;

//     .title {
//       font-style: normal;
//       font-weight: 600;
//       font-size: 13px;
//       line-height: 16px;
//       display: flex;
//       align-items: center;
//       letter-spacing: -0.5px;
//       color: #ffffff;
//     }

//     p {
//       margin-top: 4px;
//       font-style: normal;
//       font-weight: 400;
//       font-size: 11px;
//       line-height: 13px;
//       display: flex;
//       align-items: center;
//       letter-spacing: -0.5px;
//       color: #ffffff;
//     }

//     .scan_button_wrap {
//       margin-top: 16px;

//       button {
//         padding: 4px 6px;
//         font-weight: 500;
//         font-size: 13px;
//         line-height: 16px;
//       }
//     }
//   }
// }
// .input_boxer:not(.hidden) {
//   display: flex;
//   align-items: center;
// }

// @media screen and (max-width: $breakpoint-md){
//   .onboarding_wrapper {
//     .form_wrapper{
//       width: 100%;
//       max-width: 704px;
//     }
//   }
// }

// @media screen and (max-width: $breakpoint-xs) {
//   .onboarding_wrapper {
//     padding-bottom: 32px;
//     .main_banner_wrap {
//       .title {
//         font-size: 20px;
//         line-height: 39px;
//         letter-spacing: -1px;
//       }
//     }

//     .form_wrapper {
//       margin-top: 32px;
//       max-width: none;
//       width: 90%;
//       .buttons_wrap {
//         margin-top: 32px;

//         // button {
//         //   margin-right: 32px;
//         //   margin-left: -16px;
//         // }
//       }

//       .forms_switch_box {
//         .form_container {
//           padding-left: 0px;
//           padding-right: 0px;
//           float: left;

//           .icon_wrap {
//             @include flex_row();
//             justify-content: flex-start;
//             align-items: flex-start;
//           }

//           .helper-text{
//             top: 0;
//           }

//           .question_row{
//             width: 100%;
//             &.date_input, &.citizen {
//               width: 100%;
//             }
//           }

//           .form_box {
//             &.loan_preferences_wrap {
//               padding: 15px;
//             }

//             .question_row {
//               &:not(.horizontal_radio) {
//                 .radio_box {
//                   align-items: flex-start;
//                   justify-content: flex-start;
//                   .radio_item {
//                     &:not(:last-child) {
//                       margin-bottom: 20px;
//                     }
//                   }
//                 }
//               }

//               .question {
//                 margin-bottom: 16px;
//               }

//               .closure_input {
//                 @include flex_column();
//                 align-items: flex-start;
//               }

//               .button_group_selector {
//                 @include flex_column();
//                 width: 100%;
//                 max-width: 246px;

//                 button {
//                   width: 100%;
//                   @include flex_column();
//                   &:not(:last-child){
//                     border-bottom: 1px solid #ebebeb;
//                   }
//                 }
//               }

//               &.name {
//                 width: 100%;
//                 .input_box{
//                   width: 100%;
//                 }
//               }

//               &.contact {
//                 width: 100%;
//                 .input_box{
//                   width: 100%;
//                 }
//               }

//               &.date_input {
//                 width: 100%;
//                 .input_box {
//                   width: 100%;
//                 }
//               }

//               &.citizen, &.dropdown {
//                 width: 100%;
//                 .input_box {
//                   width: 100%;
//                 }
//               }

//               &.credit{
//                 width: 100%;
//                 &#react-select-5-listbox{
//                   position: absolute;
//                   z-index: 1;
//                 }
//               }

//               .purchase_inputs{
//                 .edit_field,
//                 .back_field {
//                   width: 10%;
//                 }
//               }

//               .input_box {
//                 width: 100%;
//               }

//               .button_group_selector {
//                 max-width: 100%;
//               }
//             }

//             .table_box {
//               .title {
//                 margin-bottom: 16px;
//                 width: 100%;
//               }

//               .button_group_selector {
//                 @include flex_column();
//                 width: 100%;
//                 max-width: 100%;

//                 button {
//                   width: 100%;
//                   border-bottom: 1px solid #ebebeb;
//                 }
//               }

//               &.loan_box_wrap {
//                 height: 100%;
//                 min-height: 362px;

//                 p {
//                   text-align: right;
//                 }
//               }
//             }

//             .input_wrap {
//               margin-bottom: 24px;
//               .input_box {
//                 width: 100%;
//               }
//             }
//           }
//         }
//       }
//     }

//     .scan_id_wrapper {
//       margin-left: 32px;
//       margin-right: 32px;
//     }
//   }
// }

// @media screen and (max-width: calc($breakpoint-xxs + 120px)) {
//   .onboarding_wrapper{
//     .form_wrapper{
//       .forms_switch_box{
//         .form_container{
//           .form_box{
//             .debt_inputs{
//               flex-direction: column;
//               gap: 0;
//             }
//           }
//         }
//       }
//     }
//   }
// }
