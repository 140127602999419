@import './../../variables.scss';

.animation_glare {
  position: fixed;
  left: -350px;
  top: calc(50% - 120px);
  @include flex-row();
  flex-wrap: wrap;
  animation: bigWave 4.5s infinite alternate ease-in-out;

  &.spotlight {
    left: 90px;
    animation: fadeIn 2s 1 ease-in-out;
    top: calc(50% - 240px);
    position: absolute;

    .glare1 {
      background: #a358ff;
      animation: slideLtRBig 4s infinite alternate;
    }
    .glare2 {
      background: #f1be3a;
      animation: slideLtRBig 2s infinite alternate;
      opacity: 0.56;
    }

    .glare3 {
      display: none;
    }
  }

  .glare1 {
    width: 256px;
    height: 256px;
    background: linear-gradient(272.72deg, #202d59 7.05%, #624ce0 101.24%);
    filter: blur(124px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    animation: slideLtR 0.5s infinite alternate;
  }

  .glare2 {
    width: 176.85px;
    height: 176.85px;
    background: linear-gradient(272.72deg, #202d59 7.05%, #624ce0 101.24%);
    filter: blur(116px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    animation: slideLtR 0.8s infinite alternate;
  }

  .glare3 {
    width: 100%;
    height: 150px;
    background: linear-gradient(279.95deg, #624ce0 10.56%, #a459ff 82.49%);
    filter: blur(116px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    animation: slideLtR 1.5s infinite alternate;
  }
}

.slide_item {
  @include flex-column();
  justify-content: space-between;
  padding-bottom: 71px;
  padding-top: 54px;
  padding-right: 51px;
  padding-left: 64px;
  align-items: flex-start;
  height: calc(100vh);
  position: relative;
  color: #ffffff;

  &.black {
    color: #000;

    &:before {
      display: none;
    }
  }

  &:before {
    @include pseudo-abs();
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    z-index: 2;
  }

  .para_wrap {
    z-index: 3;
    position: absolute;
    bottom: 84px;
    text-align: left;

    p {
      &.larger {
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;
        max-width: 427px;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        display: flex;
        align-items: center;
        letter-spacing: -2.5px;
        font-feature-settings: 'ordn' on;
      }

      &.smaller {
        width: 100%;
        max-width: 496px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.5px;
      }
    }
  }
}

.number_animator {
  font-variant-numeric: tabular-nums;
}

.progress_wrap {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #000000;
  @include flex-column();
  width: 100%;
  align-items: flex-start;
  position: relative;

  .text {
    position: absolute;
    top: 10px;
  }

  &.reverse {
    align-items: flex-end;

    .text {
      order: 2;
    }

    .progress_bar {
      order: 1;
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .progress_bar {
    background: rgba(10, 93, 255, 0.2);
    border-radius: 16px;
    overflow: hidden;
    height: 5px;
    width: 100%;
    margin-top: 8px;

    .current_progress {
      background: #0a5dff;
      height: 100%;
      width: 0;
      transition: width 0.3s;
      animation: widthFromLeft 0.4s 1;
    }
  }
}

.get_started_banner_section {
  background: #a459ff;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
  }

  .content_wrap {
    padding: 96px 0!important;
    position: relative;
    @include flex-column();

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 58px;
      line-height: 72px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin-bottom: 40px;
    }
  }
}

.progressWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  .progressStep {
    display: flex;
    align-items: center;
    color: white;
    position: relative;
    gap: 1rem;
    &.current {
      .progressStep__number {
        background-color: white;
        color: black;
      }
    }
    .progressStep__number {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      &::before {
        content: "";
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .progress__separator {
    height: 1px;
    background: white;
    width: 10%;
    align-self: center;
  }
}

.address-bar {
  background-color: #f2e8ff;
  padding: 1rem 0;
  .address-bar__container{
    width: 100%;
    display: flex;
    max-width: 800px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-family: "Inter", sans-serif;
    margin: auto;
    .address-bar__address{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .25rem;
    }
  }
}

.registrationPopup{
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f2e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $breakpoint-md){
  .progressWrapper {
    width: 100%;
    max-width: 704px;
    .progressStep{
      .progressStep__separator{
        display: none;
      }
    }
  }

  .address-bar {
    .address-bar__container{
      width: 100%;
      max-width: 704px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .get_started_banner_section {
    svg {
      height: 100%;
      right: -130px;
    }

    .content_wrap {
      padding: 64px 0!important;
      position: relative;
      @include flex-column();

      .title {
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -1px;
      }
    }
  }

  .progressWrapper {
    width: 90%;
    height: 70px;
    //padding: 0 20px;
    .progressStep {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      gap: .5rem;
      .progressStep__number {
        width: 20px;
        height: 20px;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          border: 1px solid white;
        }
      }
    }
  }

  .address-bar {
    flex-direction: column;
    gap: 1rem;
    .address-bar__container{
      font-size: 15px;
      //padding: 0 20px;
      width: 90%;
      .address-bar__address{
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs){
  .progressWrapper {
    .progress__separator {
      display: none;
    }
  }
  .address-bar {
    .address-bar__container{
      .address-bar__address{
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
