@import "@knocklabs/react-notification-feed/dist/index.css";
@import "./wrappers/NewVersion/variables";
@import "./wrappers/NewVersion/styles";

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.invisible {
  visibility: hidden;
}

.nv-modal {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  opacity: 0;
  pointer-events: none;

  &-top {
    justify-content: flex-start;
  }
  &-enabled {
    opacity: 1;
    pointer-events: auto;
  }

  &-disabled {
    opacity: 0;
  }

  &__content {
    &__top {
      width: 100%;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    width: 400px;
    box-shadow:
      0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    &--80vw {
      width: 80%;
      max-width: 1000px;
    }
  }

  &__iconBox {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }
}

.nv-drawer {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(8px);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  display: flex;
  &-no-back-drop {
    background: none;
    backdrop-filter: none;
    pointer-events: initial !important;
    width: fit-content;
    right: 0;
    left: auto;
    box-shadow: 1px -4px 5px 1px gray;
    transition: opacity 0.3s ease-in-out;
    // overflow: auto;
  }
  &-enabled {
    opacity: 1;
    pointer-events: auto;
  }

  &-disabled {
    opacity: 0;
  }

  &__backdrop {
    flex: 1;
  }

  &__closeButton {
    margin-left: auto;
    padding: 8px;
    border: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-left: 1px solid #eaecf0;
    box-shadow:
      0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    overflow: auto;
    &-sm {
      width: 400px;
    }

    &-md {
      width: 760px;
    }

    &-lg {
      width: 920px;
    }
    &-elg {
      width: 63vw;
    }
  }

  &__contentHeader {
    padding: 24px 24px 16px 24px;
  }

  &__contentBody {
    overflow-y: hidden;
    flex: 1;
  }

  &__contentFooter {
    padding: 16px 24px;
  }
}

.nv-menu {
  inset: 0;
  position: fixed;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow:
    0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  width: max-content;
  height: max-content;
}

.react-portal-drawer-container {
  overflow: auto;
}
