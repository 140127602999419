.flex-grow-for-badge {
    flex: 3 0 0;
}

.flex-grow-for-rest {
    flex: 3 0 0;
}
  
@media (min-width: 1450px) {
    .flex-grow-for-badge {
        flex: 2 0 0;
    }
}