@import "./../../../../src/variables.scss";

.onboardingFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #e5e5e5;
  height: 8px;
  align-self: stretch;
  border-radius: 0px 8px 8px 0px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 2;
  flex-grow: 0;
  @media screen and (min-width: $breakpoint-xs) {
    height: 24px;
  }
  .footerProgress {
    background: #62d1a4;
    height: 100%;
  }
}
